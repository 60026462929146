.container {
    display: flex;
    flex-direction: row;
}

.sizeTagItem {
    width: 50px;
    text-align: center;
    border: 1px solid #999;
    border-right: none;
    cursor: pointer;
}

.sizeTagItemActive {
    background-color: #01A699;
    color: white;
}

.sizeTagItem:last-child {
    border-right: 1px solid #999;
}