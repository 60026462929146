@import '~global/styles';

.tips {
  position:relative;
  box-shadow: 0px 2px 28px 4px rgba(0, 0, 0, 0.14);
  border: 1px solid rgba(151, 151, 151, 0.14);
  width: 322px;
  color: #666666;
  &:before{
    content: " ";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid #fff;
    border-right: 10px solid transparent;
    position: absolute;
    left: 50%;
    top: -19px;
    border-left: 10px solid transparent;
  }
  padding:35px 35px 35px 35px;
  .title {
    height: 18px;
    font-size: 22px;
    color: #EC7765;
    line-height: 18px;
  }

  .content {
    font-size: 12px;
    border-top: 1px solid rgba(151, 151, 151, 0.33);

    margin: 8px 0;
    padding: 8px 0 20px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.33);
    white-space: normal;
    &>div:first-child{
      display: flex;
      align-items: center;
    }

    &>div>label {
      font-weight: bold;
    }
  }
  .contentInner{
    padding: 20px;
    margin: 0;
  }

  .footer {
    font-size: 11px;
    color: #666666;
  }

  span {
    color:$green
  }
}
