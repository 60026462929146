@import '~global/styles';

@mixin textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.select {
  width: fit-content;
  min-width: 140px;
  height: 36px;
  background: transparent;
  font-size: 14px;
  cursor: pointer;
  position: relative;

  .selector {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    padding-right: 5px;
    border-radius: 18px;
    border: solid 1px $text-light;
    color: $text-dark;

    .selectorSelection {
      flex: 1;
      font-size: 14px;
      cursor: pointer;
      border: none;
      outline: none;
      @include textEllipsis;
    }

    .selectorArrow {
      display: flex;

      > svg {
        height: 20px;
        fill: $text-dark;
        transition: transform .5s;
      }
    }

    .selectorArrowFlipped {
      > svg {
        transition: transform .5s;
        transform: rotate(180deg);
      }
    }
  }
  
  .dropdown {
    display: none;
    @include textEllipsis();
  }

  .dropdownExpanded {
    display: initial;
    position: absolute;
    top: 50px;
    left: 0;
    border-radius: 4px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;
    color: $text-dark;
    width: max-content;
    cursor: initial;
    z-index: 1000;
    text-align: left;
  }
}

// 禁用
.disabled {
  color: $text-light;
  background: none;
  cursor: not-allowed;
  opacity: 0.5;

  .selectorSelection {
    cursor: not-allowed !important;
  }
}

// 被选中
.isSelected:not(.disabled) {
  border: solid 1px $green;
  
  .selectorSelection {
    color: $green;
  }

  .selectorArrow {
    > svg {
      fill: $green;
    }
  }
}