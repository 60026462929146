$arc-border-shadow-gap: 1px;
$arc-border-shadow-color: #ffffff;
$arc-border-shadow: 1px 1px $arc-border-shadow-gap 0.5px $arc-border-shadow-color,
  1px -1px $arc-border-shadow-gap 0.5px $arc-border-shadow-color,
  -1px 1px $arc-border-shadow-gap 0.5px $arc-border-shadow-color,
  -1px -1px $arc-border-shadow-gap 0.5px $arc-border-shadow-color;

@mixin commonCover {
    box-shadow: $arc-border-shadow;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

$smallRadius: 70px;
$mediumRadius: 140px;
$bigRadius: 210px;
$borderRadius: 270px;

.pieChart {
    height: $borderRadius;
    margin-top: 20px;
    position: relative;

    .noData{
        text-align: center;
        padding-top: 100px;
    }

    .smallCover {
        width: $smallRadius;
        height: $smallRadius;
        border-radius: $smallRadius;
        background-color: rgba($color: #fff, $alpha: 0.5);
        @include commonCover();
    }

    .mediumCover {
        width: $mediumRadius;
        height: $mediumRadius;
        border-radius: $mediumRadius;
        background-color: rgba($color: #fff, $alpha: 0.4);
        @include commonCover();
    }

    .bigCover {
        width: $bigRadius;
        height: $bigRadius;
        border-radius: $bigRadius;
        background-color: rgba($color: #fff, $alpha: 0.3);
        @include commonCover();
    }

    .borderCover {
        width: $borderRadius;
        height: $borderRadius;
        border-radius: $borderRadius;
        @include commonCover();
        border: 1px solid rgba(0,0,0,0.1);
    }
}

.pieModal {
    position: fixed;
    left: -200px;
    top: -200px;
    width: 220px;
    z-index: 8;
    background-color: rgb(255, 255, 255);
    padding: 14px 18px 18px 14px;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.5), 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    &::after {
        position: absolute;
        left: 50%;
        bottom: 100%;
        margin-left: -10px;
        /* 为父容器内边距的值 */
        content: ' ';
        border-top: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid rgb(255, 255, 255);
        border-left: 10px solid transparent;
    }

    .title {
        display: flex;
        color: #666666;
        font-family: "Nunito Sans";
        font-size: 11px;
        font-weight: 400;
    }

    .secound {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 4px;

        .numberFont {
            color: #666666;
            font-family: "Nunito Sans";
            font-size: 16px;
            font-weight: 400;
            border-right: 2px solid #c2c2c2;
            padding-right: 10px;
        }

        .dot {
            width: 11px;
            height: 11px;
            border-radius: 50%;
            margin-left: 6px;
            border: 0.35px solid #A0A0A0;
        }

        .name {
            flex: 1;
            color: #666666;
            font-family: "Nunito Sans";
            font-size: 16px;
            font-weight: 400;
            margin-left: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .category {
        color: #666666;
        font-family: "Nunito Sans";
        font-size: 11px;
        font-weight: 400;
        margin-top: 4px;
        display: flex;

        .categoryText,
        .regionText,
        .averagePriceText {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .options {
        color: #01a699;
        font-family: "Nunito Sans";
        font-size: 10px;
        font-weight: 400;
        margin-top: 4px;

    }
}