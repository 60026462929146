.modal {
    background: #fff;
    min-height: 300px;
    min-width: 480px;
    width: 1200px;
    height: 600;
    z-index: 12;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -40%);

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #cccccc;
        padding-left: 20px;
        padding-block: 10px;

        .closeIcon {
            width: 26px;
            height: 26px;
            margin: 0 10px;
        }
    }

    .body {
        width: 100%;
        margin: 0 auto;
        padding: 20px;
        max-height: 500px;
        flex: 1;
        overflow: scroll;
        cursor: auto;
        margin-right: 20px;
    }

    .footer {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}