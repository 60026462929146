.wrapper {
    padding-bottom: 80px;
}

.moduleTitle {
    margin-bottom: 20px;
}

.form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;
    margin-bottom: 30px;
    position: relative;
    z-index: 1111;

    .flex1 {
        flex: 1;
    }
}

.topInfo {
    display: flex;
    flex-direction: row;
    gap: 45px;
    height: 360px;
    margin-bottom: 40px;

    .leaderboardCard {
        width: 33.3%;
        height: 100%;
        border-radius: 18px;
        box-shadow: 4px 2px 15px rgba($color: #1E1E1E, $alpha: 0.15);
        padding: 20px;
    }

    .lineGraphCard {
        flex: 1;
        height: 100%;
        border-radius: 18px;
        box-shadow: 4px 2px 15px rgba($color: #1E1E1E, $alpha: 0.15);
        padding: 20px;
    }

    .cardWrapper {
        border: 1px solid #e9e9e9;
        cursor: pointer;
    }

    .cardWrapper:hover {
        border: 1px solid #74b6b6;
    }
}