@import '~global/styles';
@import '~global/g.module';
.blankClass {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0px;
  background: white;
  border-bottom: 1px solid #d8d8d8;
}

.svgColor {
  svg {
    fill: #74b6b6;
  }
}

.collapse{
  background-color: white; 
  position: relative;
  transition: all .5s ease 0s;
}

.collapseLine {
  position: absolute;
  left: 0px;
  bottom: 0px;
  border-bottom: 2px solid rgb(1, 166, 153);
  right: 0;
  z-index: 99;

  &.collapseLineTop {
    bottom: auto;
    top: -46px;
  }

  svg {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 550px;
    top: -14px;
    z-index: 1;
    background: $white;
    border-radius: 30px;
    box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.3);
  }
}

/* ********************************* arrowCell *************************************** */
.arrowCell {
  position: sticky !important;
  right: 0;
}
