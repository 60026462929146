@import '~global/styles';
@import '~global/g.module';


.transitionRow {
  -webkit-transition: height 0.2s;
  transition: height 0.2s;
  height: auto;
}

.subTitle {
  font-size: 16px;
  color: $text-dark;
  line-height: 19px;
  padding-bottom: 25px;
}

.marketFilters {
  .lineTitle{
    font-size:20px;
    color:$text-dark;
    padding: 27px 20px 0 0;
  }
  & > div {
    & > div {
      & > button {
        &:first-child {
          margin-left: 0 !important;
        }
      }
    }
  }
}

.metricsTitle {
  // margin-top: 29px;
  font-size: 20px;
  margin-left: 0px;
  color: $text-dark;


  .tag {
    font-size: 11px;
    font-family: 'Nunito Sans';
    color: $text-dark;
    line-height: 13px;
  }

  .title {
    display: inline-block;

    padding-left: 15px;
    padding-top: 9px;
    color: $text-light;
    font-size: 11px;

    & > svg {
      width: 18px;
      height: 18px;
      position: relative;
      top: 2px;
    }
  }
}

// .marketFilters {

// }

.metricsContent {
  display: flex;
  justify-content: space-between;
  width: 1440px;
  margin-left: 0px;
  border-top: 2px solid rgba(0, 0, 0, 0.03);

}

.loadingContainer2 {
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  // margin-bottom: 10px;
  // margin-top: 10px;
  width: 1400px;
  height: 100%;
  z-index: 101;
  padding: 20%;

  > div {
    left: calc(50%);
    top: calc(50%);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    // position: inherit;
  }
}

.tablev2 {
  td:first-child {
    width: 120px !important;
  }
}
.tablecell1 {
  padding: 2px 5px;
  height: 42px;
  border-bottom: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  line-height: 1.2;
  display: block;
  white-space: pre-wrap;
  display: flex;
  flex: 1;
  align-items: center;
  color: #484848;
  & > svg {
    position: relative;
    // top: 9px;
    width: 22px;
    height: 25px;
    margin-right: 3px;
  }

  word-break: normal;
  // white-space: nowrap;

}
.cellBorder {
  border-bottom: 1px solid #d8d8d8;
}

.tableCellTwo {
  display: flex;
  justify-content: space-between;
  color: $text-dark;
  background-color: rgba(116, 182, 182, 0.15);
  align-items: baseline;
  height: 34px;
  padding: 0px 5px;
  line-height: 34px;
  margin: 4px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  span:last-child {
    color: #74b6b6;
    font-size: 10px;
  }
}


.tablecell-category {
  // width: 124px !important;
  // width: 100% !important;
  width: 176px !important;
}


.tablecellCategory {
  padding-left: 5px;
  padding-right: 5px;
  // background-color: #d9e7ea;
  & > svg {
    position: relative;
    top: 5px;
    width: 14px;
    height: 20px;
    margin-right: 3px;
  }
}


