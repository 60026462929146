@import '~global/styles';

// should go in a component
$logoSize: 28px;
.header {
  // border-bottom: 1px solid $border-bottom;
  // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 68px;
  right: 0;
  top: 0;
  z-index: 999999;
  background-color: white;
}

.appName {
  font-size: $font-size-xxl;
  color: $text-dark;
  align-self: center;
}

.topNav {
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;

  > div {
    height: 100%;
    margin-left: 30px;
  }
}

.topBar {
  display: flex;
  align-items: center;
  padding: 0 1em;
  height: 0px;
  position: relative;
}

.logoLink {
  text-decoration: none;
  display: flex;
  margin-right: 85px;
}

.products-container {
  display: flex;
}
