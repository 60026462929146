@import '~global/styles';

.thead, .tr {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    font-family: Nunito Sans,sans-serif;
    font-size: 12px;
    color: #666;
}

.thead {
    margin-top: 20px;
    margin-bottom: 10px;
}

.tr {
    margin-bottom: 10px;
}

.trTotal {
    font-weight: bold;
    border-top: 1px solid $black;
    padding-top: 20px;
    margin-top: 20px;
}