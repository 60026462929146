
.categoryItem {
    width: calc((100% - 60px) / 6);
    aspect-ratio: 280 / 252;
    border: 1px solid grey;
    border-radius: 4px;
    overflow: hidden;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 12px;
    cursor: pointer;

    .categoryInner {
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 4px;
        position: relative;

        .categoryImg {
            & img {
                object-fit: cover !important;
            }
        }

        .categoryMask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.5);
        }

        .selectedCategoryMask {
            background-color: rgba(#74B6B6, .75);
        }

        .categoryNameWrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .categoryName {
                color: #fff;
                font-size: 18px;
                width: 100px;
                text-align: center;
            }
        }

        .footer {
            position: absolute;
            bottom: 12px;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 11px;
            color: #fff;
            user-select: none;
        }

        .selectedIcon {
            position: absolute;
            width: 24px;
            height: 24px;
            top: 10px;
            right: 10px;

            svg {
                width: 28px;
                height: 28px;
            }
        }
    }
}



.categoryItem:nth-child(6n - 5){
    margin-left: 0;
}

.categoryItem:nth-child(6n) {
    margin-right: 0;
}


