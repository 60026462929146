@import '~global/styles';

.smallItems {
  .rowContent {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    padding: 2em 1em;
    &.hidden {
      overflow: hidden;
      //height: 240px;
      height: 321px;
    }
    &.visible {
      overflow: auto;
      height: 1640px;
    }
    &.loading {
      opacity: 0.5;
    }
  }

  .rowItem {
    flex-basis: 10%;
    background-color: transparent;
    display: flex;
    justify-content: center;
  }
}

.bigItems {
  .rowContent {
    padding: 2em 1em;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;

    &.hidden {
      overflow: hidden;
      height: 555px;
    }
    &.visible {
      overflow: auto;
      height: 1640px;
    }
    &.loading {
      opacity: 0.5;
    }
  }

  .rowContentAll {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100% - 16.6%;
  }

  .rowItem {
    flex-basis: 20%;
    background-color: $background-gray;
    margin: 10px 0;
    display: flex;
    justify-content: center;
  }
}

.wrap {
  display: flex;
  width: 100%;
  //padding: 1em;
  position: relative;
}

.message {
  justify-content: center;
  font-size: $font-size-small;
  // margin-top: 20px;
}
