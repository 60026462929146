.title {
    font-size: 16px;
    font-weight: 400;
    color: #666;
    margin-bottom: 40px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 230px;
    overflow: auto;

    .item {
        display: flex;
        flex-direction: row;
        height: 20px;

        .index {
            width: 24px;
            text-align: left;
            font-size: 12px;
            color: #484848;
            font-weight: bold;
            display: flex;
            align-items: center;
        }

        .content {
            flex: 1;
            height: 20px;
            position: relative;

            .bg {
                background-color: #74B6B6;
                height: 20px;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-inline: 10px;

                .label {
                    font-size: 12px;
                    color: #fff;
                    font-weight: 400;
                    z-index: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: 10px;
                }
    
                .value {
                    font-size: 12px;
                    color: #fff;
                    font-weight: bold;
                    z-index: 1;
                }
            }
        }
    }
}