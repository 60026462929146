@import '~global/styles';
@import '../../../styles';

.fifter-content {
  cursor: pointer;
  white-space: nowrap;
  font-size: 11px;
  width: 100%;
  text-align: left;
  position: relative;

  div:first-child {
    font-size: 11px;
    color: rgba(102, 102, 102, 1);
  }

  div:last-child {
    font-size: 16px;
    color: #666;

    span:first-child {
      color: #01A699;
    }
  }
}

.filterTitle {
  border: none;
  width: 100%;
  background: transparent;
  text-align: center;
  color: #666666;

  svg,
  img {
    width: 100%;
    width: 30px;
  }

  span {
    font-size: 11px;
  }

  div {
    font-size: 16px;

    span {
      font-size: 16px;
      color: #01A699;
    }
  }
}

.filterWrapper {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button, svg {
    cursor: pointer;
  }
}

.fifter-info {
  display: flex;
  align-items: center;
  position: relative;
  outline: none;
  // width: 34px;
  // height: 34px;

  .fiflterItemWrapper {
    position: relative;
    width: 85px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      width: 36px;
    }
  }

  .disabled {
    color: #ddd !important;
    cursor: not-allowed;

    svg {
      cursor: not-allowed;
    }
  }

  .fifter-initials-arrow {
    margin-left: -10px;
    display: flex;
    width: 24px;
    cursor: pointer;

    svg {
      height: 30px;
      fill: $text-light;
    }
  }


  .fixedFifter {
    width: 280px;
    position: absolute;
    left: 87px;
    top: -20px;
    z-index: 200;
    max-height: calc(100vh - 80px);

    svg {
      height: 16px;
      width: 16px;
      fill: $text-light;
    }

    .close {
      text-align: right;
      margin-top: 5px;
      padding-right: 5px;
    }

    .title {
      color: rgba(244, 79, 17, 1);
      font-size: 22px;
      padding-left: 40px;
      font-weight: 600;
    }

    .cleanBtn {
      margin-top: 20px;

      button {
        width: 73.4%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        justify-content: space-between;

        .content {
          padding-left: 20px;
        }
      }
    }

    .clockTitle {
      margin-top: 20px;
      color: #666;
      font-size: 12px;
      position: relative;
      padding-left: 35px;

      svg {
        position: absolute;
        top: 1px;
      }
    }

    .filterLockIcon {
      width: 30px;
      display: none !important;
      height: 30px;
      border-radius: 30px;
      position: absolute;
      top: 20px;
      left: -10px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: 0px 6px 16px -8px rgba(0, 0, 0, 0.08), 6px 0px 16px -8px rgba(0, 0, 0, 0.08), -6px 0px 16px -8px rgba(0, 0, 0, 0.08), 0px -6px 16px -8px rgba(0, 0, 0, 0.08);
    }
  }

  &:hover {
    .fixedFifter {
      height: auto;
    }
  }

  .fifterBtn {

    &>button:nth-child(1),
    &>button:nth-child(2),
    &>button:nth-child(3),
    &>button:nth-child(4) {
      //border-color:$green;
      color: #666;

      svg {
        fill: $green;
      }
    }

    &>div:nth-child(1),
    &>div:nth-child(2),
    &>div:nth-child(3),
    &>div:nth-child(4) {
      border-color: $green;

      span {
        color: #666;

        &:hover {
          color: $green;
        }
      }

      svg {
        fill: $green;
      }
    }

    &>button {
      width: 73.4%;
      margin-left: auto;
      margin-right: auto;
      // border-color:#01a699;
      margin-top: 17px;
      // color:#01a699;

    }

    &>div {
      width: 73.4%;
      margin-left: auto;
      margin-right: auto;
      // border-color:#01a699;
      margin-top: 17px;

    }
  }

  .filterTitleContainer {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    padding-left: 0em;

    &:hover {
      background: #F1F1F1;

    }
  }

  .saveBtn {
    margin-top: 54px;
    margin-bottom: 40px;

    button {
      width: 73.4%;
      margin-left: auto;
      margin-right: auto;
      justify-content: space-between;

      .content {
        padding-left: 20px;
      }
    }
  }
}

span.label {
  font-size: 10px;
  line-height: 1;
  text-transform: uppercase;
  color: #666666 !important;
  width: 85px;
  display: block;
  position: relative;
  transform: scale(0.8);
  user-select: none;
}