@import '~global/styles';

.arrow {
  width: 24px;
  height: 24px;
  transition: transform 0.5s;
  fill: $icon-grey;
}

.up {
  transition: transform 0.5s;
}

.down {
  transform: rotate(180deg);
  transition: transform 0.5s;
}
