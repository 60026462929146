.header {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    background: #fff;
    z-index: 1;
  
    .productModal {
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 10px;
      margin-top: 10px;
  
      > div {
        > svg {
          &:last-child {
            width: 35px;
            height: 40px;
          }
        }
      }
    }
}