@import '~global/styles';
@import '~global/g.module';
.metricsTab {
  width: 145px;

  & > div,
  & > main > div {
    width: 100%;
    background-color: $markdown-footer-darker;
    color: #9b9b9b;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    transition: all .5s ease 0s;
    height: 44px;

    & > button {
      background-color: $markdown-footer-darker;
      border:none;
      transition: lineHeight .5s ease 0s;
      cursor: pointer;
      width: 100%;
      color: #9b9b9b;
      display: inline-block;
      &.tabBtn{
        display: flex;
        align-items: center;
        min-height: 37px;
      }
      &>svg{
        width:25px;
      }
      .tabText{
        transition: all .5s ease 0s;
        white-space: break-spaces;
        .innerText{
          display: flex;
          height: 100% !important;
          align-items: center;
          justify-content: center;
        }
      }
      &>span:last-child{
        min-width:25px;
      }
    }
  }
  .activeTab {
    background-color: $green-bg !important;
    color: $tundoraBlack;
    .tabText{
      justify-content: center;
      align-items: center;
      display: flex;
      color: white !important;
    }
    .tabText.sub-tab{
        color: $green;
    }
    &:hover .tabText{
      color: #484848 !important;
    }
  }
}

.buttonHasIcon {
  padding: 0 30px;
}

.buttonNoIcon {
  padding: 0 0px 0 0px;
}

.iconWrapper {
  position: absolute;
  right: 0;
  // bottom: -2px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  svg{
    fill: $green-bg;
  }
}
