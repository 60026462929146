@import '~global/styles';

.wrapper {
    margin-right: 10px;
    margin-bottom: 10px;
    font-family: Nunito Sans,sans-serif;
    padding: 10px 0px;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.05);
    background-color: #fff;
    border-radius: 7px;
    width: 240px;
    height: 355px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    .createdAt {
        padding: 0 15px;
        display: flex;
        align-items: center;
        fill: #ccc;

        svg {
            opacity: .5;
            margin-right: 4px;
            width: 13px;
        }

        span {
            padding: 5px 0;
            color: #ccc;
        }
    }

    .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        color: #666;
        padding: 0 15px;
    }

    .queryWrapper {
        padding: 0 15px;
        font-size: 12px;
        margin: 8px 0;
        border-bottom: 1px solid rgba(151, 151, 151, 0.33);
        white-space: normal;
        height: 255px;
        overflow: auto;

        &>div{
            align-items: center;
            display: -webkit-box;    
            -webkit-box-orient: vertical;    
            -webkit-line-clamp: 2;    
            overflow: hidden;
        }
    
        &>div>label {
            font-weight: bold;
        }
    }

    .closeIconWrapper {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .borderBottom {
        width: 100%;
        height: 5px;
        background: $green;
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }
}
