.wrapper {
    width: 100%;
    min-width: 1330px;
    position: relative;

    .header {
        width: 100%;
        padding: 30px 40px 10px;
        position: sticky;
        top: 0px;
        background-color: #fff;
        z-index: 1000;

        .queryForm {
            margin-bottom: 34px;
        }
    }

    .body {
        padding: 25px 40px 80px;
    }
}

.cardItem {
    width: calc((100% - 40px) / 3);
    margin-left: 10px;
    margin-right: 10px;
}

.cardItem:nth-child(3n - 2){
    margin-left: 0;
}

.cardItem:nth-child(3n) {
    margin-right: 0;
}