@import '~global/styles';
@import '~global/g.module';
@import '~global/stylesv2.module';
@import '~componentsv2/TradingTableV2/NornaTable/styles.module';

.metricsContent {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 1440px;
  margin-left: 0px;
  border-top: 2px solid rgba(0, 0, 0, 0.03);
}

.container {
  background-color: #fff;
  box-sizing: content-box;
  margin: 0;

  .form {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 30px;

    .formItem {
        font-size: 10px;
        color: #666;

        &>div:first-child {
            text-align: left;
            margin-bottom: 4px;
            height: 16px;
        }
    }
  }

  .tableWrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: 1440px;
    margin-left: 0px;
    border-top: 2px solid rgba(0, 0, 0, 0.03);

    .bgWhite {
      background: #fff !important;
    }

    .bgShadow {
      background: #F5F8F8 !important;
    }

    .bgHover:hover {
      background-color: $cell-hover-background !important;
    }

    .nameWidth {
      width: 155px;
    }

    .valueWidth {
      width: 60px;
    }
  }
}

.stickyHeader {
  height: 38px;
  line-height: 38px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  position: sticky;
  background-color: #fff;
  z-index: 121;
}

.theadVendor {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 145px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  font-size: 14px;
  color: #666;
}

.theadTitle {
  font-weight: bold;
  font-size: 14px;
  color: #666;
  padding-left: 5px;
}

.theadTitlePercentSymbol {
  font-weight: bold;
  font-size: 14px;
  color: #666;
  padding-left: 5px;
  color: #74B6B6;
}

.titleItem:nth-of-type(2n + 5) {
  & > div {
    color: #74b6b6 !important;
  }
}