.wrapper {
    width: 100%;
}

.table {
    width: 100%;
}

.thead {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    font-size: 14px;
    color: #484848;
    border-bottom: 1px solid rgba($color: #1A1A1A, $alpha: 0.1);
    padding-block: 10px;
}

.tbody {
    display: flex;
    flex-direction: column;
    position: relative;

    .tr {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        color: #484848;
        padding-block: 5px;
        cursor: auto;

        .ranking {
            font-weight: bold;
        }

        .ranking,
        .reseller,
        .options,
        .total {
            box-sizing: content-box;
            height: 23px;
            line-height: 23px;
        }
    }

    .tr:nth-child(2n + 1) {
        background-color: rgba($color: #585858, $alpha: 0.1);
        background-clip: content-box;
    }

    .tr:hover {
        background-color: rgba($color: #01A699, $alpha: 0.1);
        background-clip: content-box;
    }

    .verticalLine1 {
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: rgba($color: #585858, $alpha: 0.1);
        left: 20%;
        top: 0px;
    }

    .verticalLine2 {
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: rgba($color: #585858, $alpha: 0.1);
        left: 70%;
        top: 0px;
    }
}

.ranking {
    width: 20%;
}

.reseller {
    width: 50%;
    padding-right: 15px;
    white-space: nowrap; /* 不换行 */
    overflow: hidden; /* 隐藏溢出的文本 */
    text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
}

.total {
    width: 30%;
}

.ranking,
.reseller,
.options,
.total {
    padding-left: 15px;
    box-sizing: border-box !important;
}