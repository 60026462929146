@import '~global/styles';

$firstColumnWidth: 415px;
$secondColumnWidth: 200px;
$thirdColumnWidth: 200px;
$fourthColumnWidth: 200px;
$fifthColumnWidth: 200px;
$sixthColumnWidth: 200px; 

$borderColor: #D8D8D8;
$borderLeft: 1px solid $borderColor;

.row1 {
    height: 250px;
    display: flex;
    flex-direction: row;

    .kpiTitle {
        font-size: 14px;
        font-weight: bold;
        font-family: "Nunito Sans", sans-serif;
        color: #666;
        height: 56px;
        line-height: 56px;
    }

    .chartWrapper {
        width: 140px;
        height: 124px;
    }

    .kpiDesc {
        font-size: 12px;
        color: #838282;
        height: 70px;
        line-height: 70px;
    }

    .column {
        height: 100%;
        display: flex;
        flex-direction: row;
        position: relative;

        .left {
            width: 170px;
            height: 100%;
            display: flex;
            flex-direction: column;

            .customerVendor {
                font-size: 20px;
                color: #666666;
            }

            .kpiName {
                font-size: 14px;
                color: #666666;
            }

            .region {
                font-size: 14px;
                color: #EC7765;
            }

            .currency {
                font-size: 12px;
                color: #666666;
            }

            .date {
                font-size: 12px;
                color: #838282;
                position: absolute;
                bottom: 45px;
                left: 0px;
            }

            .remark {
                font-size: 12px;
                color: #838282;
                height: 70px;
                line-height: 70px;

                & span:first-child {
                    color: $primary-color;
                }
            }
        }
        
        .right {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;

            .kpiName {
                font-size: 14px;
                font-weight: bold;
                font-family: "Nunito Sans", sans-serif;
                color: #666666;
                margin-bottom: 15px;
            }
        }
    }

    .column1 {
        width: $firstColumnWidth;
    }

    .column2 {
        width: $secondColumnWidth;
        border-left: $borderLeft;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .column3 {
        width: $thirdColumnWidth;
        border-left: $borderLeft;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .column4 {
        width: $fourthColumnWidth;
        border-left: $borderLeft;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .column5 {
        width: $fifthColumnWidth;
        border-left: $borderLeft;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .column6 {
        width: $sixthColumnWidth;
        border-left: $borderLeft;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.colorPrimary {
    color: $primary-color;
}

.row2 {
    height: 32px;
    width: 99%;
    border-radius: 16px;
    border: 1px solid $borderColor;
    display: flex;
    flex-direction: row;

    .marketName {
        width: 100px;
        height: 32px;
        line-height: 32px;
        background-color: $primary-color;
        border-radius: 16px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        font-family: "Nunito Sans", sans-serif;
        color: #fff;
    }

    .currentValue {
        font-size: 18px;
        color: #666666;
        font-weight: bold;
    }

    .changePercent {
        font-size: 14px;
        margin-left: 22px;
        font-weight: bold;
    }

    .column1 {
        width: 315px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-left: 70px;
    }

    .column2,
    .column3,
    .column4,
    .column5,
    .column6 {
        width: $secondColumnWidth;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}
