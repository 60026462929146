@import '~global/styles';

@mixin textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.label{
  font-size: 10px;
  color: #666;
  text-align: left;
  margin-bottom: 4px;
  height: 16px;
  padding-left: 15px;
  text-transform: uppercase;
}

.selector {
    width: 120px;
    height: 36px;
    border-radius: 18px;
    border: solid 1px #666;
    background: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    outline: none;
    padding-left: 15px;
    padding-right: 15px;

    .selectorSelection {
      font-size: 14px;
      color: #666;
      cursor: pointer;
      border: none;
      outline: none;
      user-select: none;
      text-align: left;
      @include textEllipsis;
    }

    .selectorArrow {
      display: flex;

      > svg {
        height: 20px;
        fill: $text-dark;
        transition: transform .5s;
      }
    }

    .selectorArrowFlipped {
      > svg {
        transition: transform .5s;
        transform: rotate(180deg);
      }
    }
}

.selectorSmall {
  height: 28px;
}
