@import '~global/styles';

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 350px;
  background: $white;
  height: 300px;
  z-index: 12;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  margin-left: -120px;
  margin-top: -150px;
  border-radius: 3px;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $filter-border;
    height: 40px;
    padding-left: 20px;

    svg {
      width: 26px;
      height: 26px;
      margin: 0 10px;
    }
  }

  .body {
    height: 180px;
    width: 50%;
    margin: 0 auto;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      input {
        border: none;
        width: inherit;
        outline: none;
        color: $green;
      }

      &:last-child {
        > div {
          &:first-child {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
            position: relative;
            left: -10px;
            top: inherit;

            svg {
              border-radius: 50%;
              width: 15px;
              height: 15px;
              left: 2px;
            }
          }
        }
      }
    }

    .selectedProblem {
      border: 1px solid $green;
      color: $green;

      &:not(:last-child) {
        svg {
          fill: $green;
        }
      }
    }
  }

  .footer {
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  button {
    width: 160px;
    margin-bottom: 15px;
    position: relative;

    svg {
      position: absolute;
      left: -10px;
    }

    div {
      &:last-child {
        width: 92px;
      }
    }
  }

  .selectedInput {
    width: 300px;
    margin-bottom: 15px;
    position: relative;

    div {
      &:last-child {
        width: 257px;
      }
    }
  }

  .unfocusedInput {
    cursor: pointer;
  }

  &:after {
    content: '';
    border-top: 10px solid $white;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    position: absolute;
    transform: rotate(180deg);
    top: -10px;
    right: 50%;
  }
}

.closeModal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}
