@import '~global/styles';
.lineSplit{
  background: #e4e4e4;
  height: 1px;
  margin: 15px auto;
  width: 90%;
}
.treeContent{
  overflow: hidden;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.35);
  padding-bottom: 30px;
}
.arrow {
  height: 24px;
  width: 24px;
  position: absolute;
  transform: rotate(315deg);
  top: 34px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.35);
  background: white;
  z-index: 1;
  clip-path: polygon(-40% -40%, 140% -40%, -40% 140%);
  left: -11px;
}
.panelMask{
  height: 110%;
  width: 120%;
  position: absolute;
  left: -5%;
  z-index: -1;
  top: -5%;
}
.norna-tree {
  width: 280px;
  z-index: 0;
  position: relative;
  // box-shadow: 0px 1px 10px 0px rgba(0, 0, 0,  0.35);
  font-size: 14px;
  font-family: "Nunito Sans", sans-serif;
  color: #666666;
  margin-left: 0px;

  .tree-title {
    color: #ec7765;
    font-size: 16px;
    line-height: 1;
    padding: 22px 19px 12px 25px;
    text-align: left;
    cursor: default;
  }

  .checkbox {
    width: 15px;
    height: 15px;
    background: rgba(204, 204, 204, 0.24);
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0;

    .minus {
      width: 13px !important;
      height: 2px;
      background: #000;
    }

    svg {
      width: 13px !important;
      height: 13px !important;
      fill: #000 !important;
    }
  }
}

.norna-tree-item-container {
  height: 32px;
  position: relative;
  cursor: pointer;

  .norna-tree-item-panel {
    // 这里使用 visibility: hidden 而非 display: none，原因是前者保留 html 元素的位置
    // 即便元素隐藏，也可以通过 ref.current.getBoundingClientRect() 获取元素宽高等尺寸
    visibility: hidden;
    position: absolute;
    left: 100%;
    right: auto;
    z-index: 999;
    width: 280px;
    background: #FBFBFB;
    line-height: 32px;
    padding: 7px 0;
    border-radius: 9px;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.35);
  }

  .norna-tree-item-label {
    height: 32px;
    position: relative;
    padding: 0px;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 25px;
    overflow: hidden;
    outline: none;
    .label {
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      font-size: 14px;
      background: transparent;
      border: none;
      cursor: pointer;
      text-align: left;
      color: #666666;
    }

    .tree-cheroven-left {
      width: 35px;
      height: 18px;
      justify-content: center;
      align-items: center;
      display: flex;
      border: none;
      background: transparent;
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
        fill: #666;

        path:nth-of-type(2) {
          stroke: #666;
        }
      }
    }

    .tree-label {
      height: 32px;
      align-items: center;
    }


  }

  img {
    display: none;
  }


  &.active > .norna-tree-item-label {
    .label {
      color: $green;
    }

    svg {
      fill: $green;
    }

    .tree-cheroven-left {
      svg {
        fill: $green;

        path:nth-of-type(2) {
          stroke: $green;
        }
      }
    }
  }

  &.hover,
  &:hover {
    background: #F1F1F1;

    & > .norna-tree-item-label {

      .label {
        color: $green;
      }

      svg {
        fill: $green;
      }

      .tree-cheroven-left {
        svg {
          fill: $green;
  
          path:nth-of-type(2) {
            stroke: $green;
          }
        }
      }
    }

    & input[readonly] {
      color: $green;
    }

    & > .norna-tree-item-panel {
      visibility: visible;
    }

    img {
      display: inline-block;
    }
  }

  &.disabled > .norna-tree-item-label {
    cursor: not-allowed !important;
    background: #FbFbFb;

    .label {
      color: #ccc !important;

    }

    svg {
      fill: #ccc !important;
    }

    .tree-cheroven-left {
      svg {
        fill: #ccc;

        path:nth-of-type(2) {
          stroke: #ccc;
        }
      }
    }
  }

  &.masked > .norna-tree-item-label {
    background: #FbFbFb;

    .label {
      color: #ccc !important;

    }

    svg {
      fill: #ccc !important;
    }

    .tree-cheroven-left {
      svg {
        fill: #000 !important;

        path:nth-of-type(2) {
          stroke: #ccc;
        }
      }
    }
  }
}
.containerCustom{
  display:flex;
  flex-direction:row;
  &>div{
    position: relative;
  }
  &>img{
    width: 27px;margin-right: 15px;
  }
}

.flex {
  display: flex;
}

.disabled {
  cursor: not-allowed !important;
  background: #FbFbFb;
  color: #ccc !important;

  .label {
    color: #ccc !important;

  }

  svg {
    fill: #ccc !important;
  }
}

.masked {
  background: #FbFbFb;
  color: #ccc !important;

  .label {
    color: #ccc !important;

  }

  svg {
    fill: #000 !important;
  }
}

.flex1 {
  flex: 1;
}
.launch-after{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  line-height: 32px;
  padding-left: 25px;
  text-align: left;
  &.active{
    color:$green;
  }
}

.infoIcon {
  width: 20px !important;
  height: 20px !important;
  fill: rgb(202, 202, 202) !important;
  transform: scale(1) !important;
  margin-right: 6px;
}
