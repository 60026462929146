.imageWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 170px;
  width: 100%;
  background-color: white;
}

.image {
  width: 100%;
}

.placeholder {
  display: block;
  width: 100%;
  height: 100%;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSJ3aGl0ZSI+CiAgPHBhdGggZD0iTTAgNCBMMCAyOCBMMzIgMjggTDMyIDQgeiBNNCAyNCBMMTAgMTAgTDE1IDE4IEwxOCAxNCBMMjQgMjR6IE0yNSA3IEE0IDQgMCAwIDEgMjUgMTUgQTQgNCAwIDAgMSAyNSA3Ij48L3BhdGg+Cjwvc3ZnPg==')
    no-repeat center hsl(0, 0%, 80%);
  background-size: calc(100% / 3);
}
.loadingContainer{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgContainer{
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover !important;
}
.noLimitSizeBg{
  background-size: contain !important;
  &>img{
    object-fit: contain !important;
  }
}
.imgContain{
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;;
}