@import '~global/styles';

.noData {
  min-height: 324px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:101;
  background: $body-background;
}

.fullWidth {
  width: 100%;
}

.small {
  min-height: 100px;
}
