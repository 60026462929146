.arrow {
  width: 24px;
  height: 24px;
  transition: transform 0.5s;
  fill: grey;
}

.up {
  transition: transform 0.5s;
}
.right {
  transform: rotate(90deg);
  transition: transform 0.5s;
}
.down {
  transform: rotate(180deg);
  transition: transform 0.5s;
}
.left {
  transform: rotate(270deg);
  transition: transform 0.5s;
}
