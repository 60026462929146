@import '~global/styles';
//@import '~styles';

.account-info {
  cursor: pointer;
  display: flex;
  //border-right: 1px solid rgba(151, 151, 151, 0.19);
  align-items: center;
  position: relative;
  outline: none;
  border-image: linear-gradient(to bottom,
          rgba(255, 255, 255, 1) 1px,
          rgba(151, 151, 151, 0.19) 1px,
          rgba(151, 151, 151, 0.19) 30px,
          rgba(255, 255, 255, 1) 30px);
  border-image-slice: 1;
  //padding-left: 1em;
  padding: 2px 15px;
  margin: 0 5px;
//}
  .account-details {
    display: initial;
    position: absolute;
    min-width: 170px;
    top: 45px;
    right: 0;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
    width: max-content;
    z-index: 5;
    font-size: $font-size-medium;

    .arrow {
      position: relative;
      display: block;
      background: transparent;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);



      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        margin-left: -1em;
        margin-top: -1em;
        right: 24%;
        top: 9px;
        box-sizing: border-box;
        border: 0.4em solid black;
        border-color: transparent transparent $white $white;
        transform-origin: 50% 50%;
        transform: rotate(135deg);
        box-shadow: -5px 5px 10px 0 rgba(0, 0, 0, 0.1);
      }

    }
  }

}

div.item {
  padding: 10px 10px 10px 10px;
  color: $text-light;
  display:flex;
  align-items: center;
  >svg {
    margin-right: 15px;
    fill: $text-light;
  }
  &:hover,&.active{
    color: $green;
    svg {
      color: $green;
      fill: $green;
    }
  }
}


