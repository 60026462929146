@import "~global/styles";

.show-more {
  width: 20px;
  cursor: pointer;

  .dots {
    fill: $text-light;
  }
}
