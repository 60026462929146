.filterInfo {
    background-color: #EC7765;
    width: 100%;
    height: 32px;
    position: relative;
    z-index: 125;

    .filterInfoContent {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #ffffff;
        padding: 0 20px;

        .filter {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
        }

        .divider {
            width: 1px;
            height: 60%;
            background-color: #ffffff;
            margin-left: 15px;
            margin-right: 15px;
        }

        .filterDetail {
            width: 400px;
            padding: 20px;
            font-size: 12px;
            color: #666666;
            word-wrap: break-word;

            &:before{
                content: " ";
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-bottom: 10px solid #fff;
                border-right: 10px solid transparent;
                position: absolute;
                left: 40%;
                top: -19px;
                border-left: 10px solid transparent;
            }

            &>div{
                margin-bottom: 10px;

                &>label {
                    font-family: "Nunito Sans";
                    font-weight: bold;
                }
            }
        }
    }
}