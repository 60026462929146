.wrapper {
    display: flex;
}

.header {
    width: 90%;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    background: #fff;
  
    .productModal {
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 10px;
      margin-top: 10px;
  
      > div {
        > svg {
          &:last-child {
            width: 35px;
            height: 40px;
          }
        }
      }
    }
}

.body {
    padding: 20px 10px;
    display: flex;
    flex-direction: row;
    margin-top: 45px;

    .imageWrapper {
      position: relative;

      div[class*="checkbox-container"]{
        transform: scale(1.8);
        background-color: #ffffffd4;
        padding: 10px 10px;
      }
      div[class*="checkboxContainer"]{
        top: -40px;
        text-align: right;
        justify-content: flex-end;
        z-index: 0;
        left: auto;
        div[class*="checkbox-container"]{
          padding: 0;
        }
      }
    }

    .moduleTitle {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        & > div:first-child {
            font-family: Nunito Sans,sans-serif;
            font-size: 16px;
            line-height: 19px;
            color: #000;
            margin-right: 20px;
        }

        svg {
            fill: #01A699;
            opacity: 1;
        }

        & > div:last-child {
            font-family: Nunito Sans,sans-serif;
            font-size: 11px;
            color: #01A699;
        }
    }

    .reportProduct {
      margin-top: 30px;
    }

    .firstPrice {
      h1 {
        font-size: 22px;
      }
    }

    .thead {
      font-size: 11px;
      color: #666;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .th {
        width: 166px;
        text-align: left;
      }
    }

    .tr {
      font-size: 12px;
      color: #666;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      height: 30px;
      line-height: 30px;

      .td {
        text-align: left;
        width: 166px;
      }

      .td:nth-of-type(3) {
        color: #31B4A7;
      }
    }
}
