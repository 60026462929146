.wrapper {
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    height: 100%;

    .form {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-bottom: 30px;
        align-items: flex-end;
        position: relative;
        z-index: 99999;
    }
}

.buttonWrapper {
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .buttonText {
        font-size: 10px;
        color: #666;
        padding-left: 15px;
    }
}