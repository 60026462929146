@import '~global/styles';

.collectionHeader {
  background: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.descriptionColumn {
  flex-basis: 11%;
  background-color: transparent;
}

.wrap {
  display: flex;
  width: 100%;
  padding: 1em;

  .list {
    width: 100%;
    min-height: 260px;
  }
}

.loading-wrapper {
  position: relative;
  height: 324px;
}
