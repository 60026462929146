.checkbox {
    display: flex;
    align-items: center;
    position: relative;
    padding: 6px 0;
    cursor: pointer;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    color: #666;

    .box {
        margin-right: 10px;
        width: 15px;
        height: 15px;
        background-color: #eeeeee;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .label {
        min-height: 19px;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.checkboxDisabled {
    opacity: 0.9;
    cursor: not-allowed;
}