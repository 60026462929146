.legend {
    height: 542px;
    width: 146px;
    background-color: #74b6b6;
    position: relative;
    overflow: hidden;

    .legendItem {
        overflow: hidden;
        white-space: nowrap;
        // text-overflow: ellipsis;
        list-style: none;
        color: #fff;
        font-size: 16px;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 30px;
        display: flex;
        align-items: center;

        &>span:first-child {
            width: 9px;
            height: 9px;
            border-radius: 4.5px;
            display: inline-block;
            margin-right: 6px;
        }

        &>span:last-child {
            // width: 95px;
            white-space: nowrap;
            max-width: calc(100% - 15px);
            // overflow: hidden;
            // text-overflow: ellipsis;
        }
    }
}