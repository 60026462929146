.item {
    display: flex;
    background-color: #f7f8f9;
    margin-bottom: 16px;
    padding: 4px 10px;

    .title {
        display: flex;
        gap: 20px;
    }
}

@import '~global/styles';

.modal {
    background: $white;
    min-height: 300px;
    min-width: 480px;
    width: 1200px;
    height: 600;
    z-index: 12;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -40%);

    .header {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $filter-border;
        height: 70px;
        padding-left: 20px;
        justify-content: space-between;

        .tabItem {
            padding: 4px 8px;
            border: 1px solid #999;
            font-size: 12px;
            cursor: pointer;
        }

        .tabItem:nth-of-type(1) {
            margin-left: 20px;
            border-right: none;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        .tabItem:nth-of-type(2) {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        .tabItemActive {
            background-color: #01A699;
            color: #fff;
        }

        .closeIcon {
            width: 26px;
            height: 26px;
            margin: 0 10px;
        }
    }

    .body {
        width: 100%;
        margin: 0 auto;
        padding-top: 10px;
        max-height: 500px;
        flex: 1;
        overflow: scroll;
        cursor: auto;
        margin-right: 20px;
    }

    .footer {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}