@import '~global/styles';

@mixin titleStyles() {
  border-bottom: 1px solid #223648;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.product-colors {
  .title {
    @include titleStyles;
  }
}

.colorDot {
  position: relative;
}

.colorDot::before {
  content: attr(data-tooltip);
  font-size: 12px;
  white-space: nowrap;
  width: fit-content;
  background-color: #fff;
  color: #666;
  text-align: center;
  padding: 4px 8px;
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  transition: display .3s;
  display: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5) !important;
  font-family: "Nunito Sans", sans-serif;
  border-radius: 1px;
}

.colorDot::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  transition: display .3s;
  display: none;
  border-color: #fff transparent transparent transparent;
}

.colorDot:hover:before,
.colorDot:hover:after {
  display: block;
}
