.wrapper {
    height: 100%;
    position: relative !important;
}

.legendWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 6px;

    .legendItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;

        .legendDot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
        }

        .legendLabel {
            font-size: 12px;
            color: #989898;
        }
    }
}