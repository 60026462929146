@import '~global/styles';

.header {
  display: block;
  width: 100%;
  background-color: $background-gray;

  // position: sticky;
  // width: 100%;
  // top: 0;
  // left: 0;
  // z-index: 4;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .container {
    padding: 15px 0 17px 14px;
  }
}

.row:nth-of-type(1) {
  height: 55px;
}

.row:nth-of-type(2) {
  height: 102px;
}
