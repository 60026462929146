$treeItemWidth: 170px;

.tree {
    min-width: $treeItemWidth;
    width: auto;
    z-index: 9999;
    max-height: 300px;
    overflow-y: overlay; 
    scrollbar-width: none; 
}
