@import '~global/styles';

.color-container {
  border: 2px solid white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: border 0.2s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 2px solid $orange;
  }

  .color {
    color: #666;
    font-family: Nunito Sans,sans-serif;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid white;
    box-sizing: content-box;
    flex-wrap: wrap;
    overflow: hidden;
    background-color: white;
    .split {
      width:  50%;;
      height: 15px;

      .split0 {
        background: red;
        border-top-left-radius: 100%;
      }

      .split1 {
        background: black;
        border-top-right-radius: 100%;
      }

      .split2 {
        background: gray;
        border-bottom-left-radius: 100%;
      }

      .split3 {
        background: yellow;
        border-bottom-right-radius: 100%;
      }
    }
  }
  &.active{
    border: 2px solid $orange;
    cursor: default;
    .color{
      cursor: default;
    }
  }
}





