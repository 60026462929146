.count {
  display: flex;
  align-items: center;
  font-size: 12px;
  

  .dot {
    width: 11px;
    height: 11px;
    border-radius: 11px;
    margin-right: 8px;
  }

  .text {
    width: 63px;
  }

  .quantity {
    margin-left: 10px;
  }
}