@import '~global/styles';

.topLogo {
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.logoImg {
  width: 42.5px;
  height: 42.5px;
}

.logoText {
  font-size: 14px;
  color: #666;
  margin-left: 10px;
}
