@import '~global/styles';
@import '~global/g.module';

.metricsTable {
  & > div{
    overflow: visible !important;
  }
  th, td {
    font-family: 'Nunito sans';
    padding:0;
    position: relative;
  }

  .contentTableRow {
    height: 20px;
  }

  .tableCellIcon {
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      fill: #74b6b6;
    }
  }
}

.title {
  display: flex;
  position: sticky;
  top: -31px;
  z-index: 11;
  background-color: white;

  div {
      font-size: 14px;
      color: #484848;
      padding-left: 8px;
      padding-top: 10px;
      font-weight: 700;
  }
}

.subtitle {
  position: sticky;
  top: 7px;
  background: #fff;
  z-index: 12;
  padding: 0;
}

.subTh {
  position: relative;

  div.svg {
    display: none !important;
  }

  div:not(.svg) {
    display: block;
  }

  &:hover {
    div.svg {
      display: flex !important;
      padding-top: 0px !important;
    }

    div:not(.svg) {
      display: none;
    }
  }
}
