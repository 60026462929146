@import '~global/styles';

.modal-wrapper {
  // position: absolute;
  // top: 30%;
  // left: 40%;
  background: $white;
  // width: 420px;
  z-index: 99999;
  border-radius: 3px;
  padding: 10px;
  cursor: auto;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);

  &.v2 {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  &.v3 {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 999999;
  }

  .fill {
    svg {
      fill: $orange;
    }
  }
}

.modal-content {
  text-align: left;
}
