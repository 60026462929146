@import '~global/styles';

.container {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: calc(100vh - 65px);
  position: relative;
  background: white;
}
