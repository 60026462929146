@import '~global/styles';

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0px;
  margin: 0;
  // justify-content: center;

  .cardWrapper {
    margin: 10px 10px 10px 0;
    position: relative;
  }

  .card {
    .innerCardWrapper {
      width: 240px;
      height: 240px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
      background-color: white;
      border-radius: 8px;
    }

    .imageWrap {
        height: 139px;
        width: 100%;
        // justify-content: center;
        // align-items: center;
        // display: flex;
        background-color: #fff;
        cursor: pointer;
        overflow: hidden;
    }

    .textWrap {
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      padding: 15px;
      height: 92px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .heading {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        display: flex;

        svg {
          margin-right: 5px;
          margin-left: -4px;
        }

        .lookbookName {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 175px;
        }
      }

      .description {
        position: relative;
        display: -webkit-box;
        max-width: 100%;
        font-size: 12px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        .lookbookDesc {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          display: inline-block;
          margin-bottom: -4px;
        }
      }
    }
  }

  .showMore {
    position: absolute;
    width: auto;
    top: 152px;
    right: 4px;
    z-index: 2;

    > div {
      > svg {
        width: 25px;
      }
    }
  }
}

.lookbookHeader {
  height: 60px;
  width: 100%;
  background-color: #fff;
  // border-top: 1px solid rgba(151, 151, 151, 0.34);
  position: relative;

  .createLookbookBtn {
    position: absolute;
    right: 0%;
    margin-top: 11px;
  }
}

.loadingContainer {
  width: 100%;
  background: $white;
  z-index: 6;
  padding: 20%;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: inherit;
  }
}