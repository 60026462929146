$thickness: 15px;
$barBg: #EDEDED;

.horizontalBar {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .valueBar {
        width: 100%;
        height: $thickness;
        background-color: $barBg;
        position: relative;
        margin-bottom: 2px;

        .currentBar {
            position: absolute;
            left: 0;
            top: 0;
            height: $thickness;
            background-color: #1D756E;
        }

        .currentValue {
            position: absolute;
            left: 0;
            bottom: 200%;
            font-size: 18px;
            color: #666;
            font-weight: bold;
        }

        .comparisonLine {
            height: 33px;
            background-color: transparent;
            border-right: 2px dashed #FB8383;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .comparisonValue {
            position: absolute;
            bottom: 19px;
            font-size: 12px;
            color: #666;
            font-weight: bold;
        }
    }

    .percentBar {
        width: 100%;
        height: $thickness;
        background-color: $barBg;
        position: relative;

        .changeBar {
            position: absolute;
            left: 0;
            top: 0;
            height: $thickness;
        }

        .changePercent {
            position: absolute;
            top: 105%;
            left: 0;
            font-size: 14px;
            font-weight: bold;
        }
    }
}

.verticalBar {
    padding-left: 18%;
    
    .valueBar {
        height: 90px;
        width: $thickness;
        background-color: $barBg;
        position: relative;

        .currentBar {
            position: absolute;
            bottom: 0;
            left: 0;
            width: $thickness;
            background-color: #1D756E;
        }

        .currentValue {
            position: absolute;
            right: 200%;
            top: 10%;
            font-size: 21px;
            color: #666;
            font-weight: bold;
        }

        .comparisonLine {
            width: 100px;
            background-color: transparent;
            border-top: 2px dashed #FB8383;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .comparisonValue {
            position: absolute;
            left: 300%;
            font-size: 12px;
            color: #666;
            font-weight: bold;
        }
    }

    .divideLine {
        width: 60px;
        height: 1px;
        background-color: #D8D8D8;
        position: relative;
        left: -10px;
    }

    .percentBar {
        height: 90px;
        width: $thickness;
        background-color: $barBg;
        position: relative;
        left: $thickness * 1.5;

        .changeBar {
            position: absolute;
            left: 0;
            top: 0;
            width: $thickness;
        }

        .changePercent {
            position: absolute;
            top: 40%;
            left: 200%;
            font-size: 18px;
            font-weight: bold;
        }
    }
}