
.rectArrow {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-family: 'Nunito Sans';

    &:hover {
        .arrow:not(.disabled) {
            background: #e6eaea;
            cursor: pointer;
        }
    }

    .arrow {
        max-height: 34px;
        height: 100%;
        background: #EDEFEE;
        border-radius: 2px 0px 0px 2px;
        padding-left: 5px;
        clip-path: polygon(0 0, 95% 0, 100% 50%, 95% 100%, 0 100%);
    }

    .label {
        position: absolute;
        left: 7px;
        line-height: 34px;
    }

    .highlight {
        background: #B9DCDD;
    }
}
