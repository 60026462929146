.metricsContent {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    max-width: 100%;
    width: fit-content;
    overflow: auto;
    border-top: 2px solid rgba(0, 0, 0, 0.03);
    scrollbar-width: none;
}

.deepBorderRight {
    border-right: 1px solid #01A699 !important;
}
