.content {
    .radio {
        width: 17px;
        height: 16px;
        margin-right: 5px;
        display: block;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 50%;
        border: 1px solid #A0A0A0;

        &.active {

            border: 1px solid #01A699;

            div {
                border-radius: 50%;
                width: 8px;
                height: 8px;
                background: #01A699;
            }
        }

    }

    .input-content {
        display: flex;
        flex-direction: row;
        width: 196px;
        height: 48px;
        background: #FFFFFF;
        border-radius: 5px;
        border: 1px solid #11AB9F;
        margin-top: 13px;
        margin-bottom: 20px;


        &>* {
            padding: 0px;
            min-width: 48px;
            background: transparent;
            border: none;

            &:first-child {
                border-right: 1px solid rgba(151, 151, 151, 0.24);
                font-size: 10px;
                flex: 1;
                padding: 5px 13px;

                input:first-child {
                    font-size: 22px;
                    display: block;
                    width: 40px;
                    border: none;
                    appearance: none;
                    padding: 0;
                    line-height: 1;
                    height: 22px;
                    outline: none;
                    color: #03A69A;
                }

                input:focus {
                    outline: none;
                    appearance: none;
                }

                &>*:last-child {
                    justify-content: space-between;
                    align-items: center;
                    display: flex;
                    font-size: 18px;
                    color: #03A69A;
                    line-height: 1;
                }
            }

            &:nth-child(2) {

                display: block;
                height: 100%;
                justify-content: center;
                align-items: center;
                display: flex;
                background: transparent;

                svg {
                    transform: rotate(90deg);

                    path {
                        fill: #11AB9F;
                    }
                }


            }

            &:last-child {
                border-left: 1px solid rgba(151, 151, 151, 0.24);

                display: block;
                height: 100%;
                justify-content: center;
                align-items: center;
                display: flex;
                background: transparent;

                svg {
                    transform: rotate(-90deg);

                    path {
                        fill: #11AB9F;
                    }

                }


            }
        }

        &.red {
            border: 1px solid #EC7765;

            &>div {
                &:first-child {
                    input {
                        color: #EC7765;
                    }

                    &>div:last-child {
                        color: #EC7765;
                    }
                }


            }

            button {
                svg {
                    path {
                        fill: #EC7765;
                    }
                }
            }
        }
    }
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}