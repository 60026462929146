@import '~global/styles';

.horizontal-line {
  left: 0;
  margin: 10px 0;
  height: 1px;
  width: 100%;
  background-color: $subtle-gray;
  border: none;
}

.smallMargin {
  margin: 0px 0px 5px 0;
}
