@import '~global/styles';

.collapse {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid $black;
        margin-bottom: 10px;
    }

    & > div {
        user-select: none;
    }
}
