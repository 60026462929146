@import '~pages/Discounts/components/DiscountsTable/styles.module';

$rangeValueItemHeight: 60px;
$rangeNextLine: 30px;

.vendors {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: auto;
    position: relative;
    top: 12px;
    scrollbar-width: none;
}

.firstColumn {
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 2;
    font-weight: 400;
    font-size: 14px;
    color: #666;
    font-family: Nunito Sans;

    .valueFirstItem {
        display: flex;
        flex-direction: column;
        width: $firstColumnWidth !important;
    }

    .valueItem {
        height: $itemHeight;
        line-height: 36px;
        padding: $py 0px;
    }

    .space {
        height: $spaceHeight !important;
        width: 100%;
    }
}

.border {
    width: 100% !important;
    height: 1px;
    background-color: $bgColor;
}

.vendor {
    width: $vendorWidth;
    font-weight: 400;
    font-size: 14px;
    color: #666;
    font-family: Nunito Sans;

    .space {
        height: $spaceHeight !important;

        div {
            height: $spaceHeight !important;
        }
    }

    .valueItem {
        width: $vendorWidth;
        height: $itemHeight;
        line-height: 36px;

        .currentValueColumn {
            width: $currentValueWidth;
            height: $itemHeight;
            padding: $pt 0px $pb $pl;
        }
        
        .comparisonValueColumn {
            width: $comparisonValueWidth;
            height: $itemHeight;
            padding: $pt 0px $pb $pl;
        }
        
        .changeColumn {
            width: $changeWidth;
            height: $itemHeight;
            background-color: rgba($color: #EDEFEE, $alpha: 0.5);
            padding: $pt 0px $pb $pl;
        }
    }
}

.rangeValueItem {
    height: $rangeValueItemHeight !important;

    .currentValueColumn,
    .comparisonValueColumn,
    .changeColumn {
        height: $rangeValueItemHeight !important;
    }

    .comparisonValueColumn > div,
    .changeColumn > div {
        position: relative;
        top: 20px;
    }
}

.backgroundNone {
    background: none;
}

.normalBorderRight {
    border-right: 1px solid $normalBorderColor;
}

.verticalDashedLine {
    position: absolute;
    height: 100%;
    width: 1px;
    left: 50%;
    top: 0;
    background-image: linear-gradient(to bottom, rgb(216, 216, 216) 55%, #fff 0%); /* 35%设置虚线点y轴上的长度 */
    background-position: right; /* top配置上边框位置的虚线 */
    background-size: 1px 10px; /* 第一个参数设置虚线点的间距；第二个参数设置虚线点y轴上的长度 */
    background-repeat: repeat-y;
    transform: translateX(-50%);
    z-index: 0;
}

.lockWrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #fff;
    z-index: 1;
}