.mdoalRoot {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999999;
}

.modalContent {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    padding: 0px 24px 20px;
    overflow: hidden;
}

.modalContentResize {
    resize: both;
}

.modalHeader {
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
    cursor: move;
    height: 44px;

    svg {
        cursor: pointer;
    }
}

.modalBody {
    cursor: default;
    height: calc(100% - 44px);
}