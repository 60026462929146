.wrapper {
    position: relative;

    .form {
        position: relative;
        z-index: 99999;
        display: flex;
        flex-direction: row;
        gap: 40px;
        align-items: flex-start;

        .title {
            font-size: 16px;
            font-weight: 400;
            color: #666;
        }
    }
}

.shopDropdown > div:nth-of-type(2) {
    overflow-x: hidden !important;
}

.moreWrapper {
    height: 20px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .dropdown {
        box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        z-index: 2000;
        position: absolute;
        top: 100%;
        right: 0px;
        padding-block: 10px;
        min-width: 250px;
        width: fit-content;

        .groupTitle {
            font-size: 14px;
            color: #666;
            padding-left: 15px;
            padding-block: 6px;
        }
    }
}
