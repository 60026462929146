@import '~global/styles';

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
}

.sizeFilter {
  width: 243px;
  background: #FBFBFB;
  box-shadow: 0px 2px 28px 4px rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  font-size: 14px;
  position: fixed;
  left: 390px;
  top: 200px;
  z-index: 99999;

  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 180px;

    .title {
      width: 100%;
      text-align: left;
      font-size: 14px;
      color: #666;
      line-height: 18px;
    }

    .subtitle {
      width: 100%;
      text-align: left;
      font-size: 11px;
      color: #AFAFAF;
    }

    .slider {
      width: 175px;
    }

    .sliderValuesContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 180px;
      color: #666666;

      span{
        border: 0.5px solid #979797;
        border-radius: 5px;
        width: 70px;
        height: 28px;
        align-items: center;
        display: inline-flex;
        justify-content: center;
      }
    }

    .applyBtn {
      width: 125px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .guideWrapper {
      font-size: 11px;
      color: #AFAFAF;
      line-height: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      user-select: none;

      svg {
        margin-top: 2px;
        margin-left: 2px;
      }

      &:hover {
        color: $primary-color;
        cursor: pointer;

        svg {
          fill: $primary-color;
        }
      }
    }
  }
}

.sizeGuideWrapper {
  background-color: #FBFBFB;
  box-shadow: 0px 2px 28px 4px rgba(0,0,0,0.1);
  padding: 33px 33px 60px 36px;
  position: relative;
  z-index: 99999;

  .sizeGuideTitle {
    font-size: 22px;
    color: #666;
    line-height: 53px;
  }

  .sizeGuideSubtitle {
    font-size: 14px;
    color: #939393;
  }

  .table {
    .thead, .tr {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .th {
      height: 31px;
      line-height: 31px;
    }

    .td {
      height: 26px;
      line-height: 26px;
    }

    .th, .td {
      width: 140px;
      text-align: center;
      font-size: 12px;
      color: #666;
    }

    .tr:nth-of-type(odd) {
      background-color: rgba(216,216,216,0.2);
    }

    .thead {
      border-top: 1px solid #B7E2DA;
      border-bottom: 1px solid #B7E2DA;
      border-left: 1px solid #B7E2DA;

      .th {
        border-right: 1px solid #B7E2DA;
      }
    }

    .tr {
      border-bottom: 1px solid #B7E2DA;
      border-left: 1px solid #B7E2DA;

      .td {
        border-right: 1px solid #B7E2DA;
      }
    }
  }
}
