@import '~global/styles';

.modalWrapper {
  position: fixed;
  background: $white;
  border-radius: 4px;
  padding: 10px;
  cursor: auto;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  z-index: 1000;
  width: 419px;
  height: 222px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .modalHeader {
    display: flex;
    justify-content: space-between;
    // border-bottom: 1px solid $subtle-gray;
    padding: 0 0 5px 0;
    cursor: move;
    .modalTitle {
      display: flex;
      align-items: center;
    }

    svg {
      height: 24px;
      width: 24px;
    }
  }

  .modalBody {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    margin: 10px;
    overflow: hidden;
    position: relative;

    .modalContent {
      display: flex;
      flex-direction: row;
      width: 85%;
      justify-content: center;
      align-items: center;
      margin-top: 35px;

      span {
        font-weight: 600;
        font-size: 23px;
      }
    }
  }

  .modalFooter {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 15px 15px 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      width: 49%;
      &:last-child {
        margin-left: 5px;
      }
    }
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 7;
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
}

.modalContainer {
  justify-content: center;
}
