@import '~global/styles';

.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  padding: 6px 0;
  cursor: pointer;

  input {
    visibility: hidden;
  }

  .checkbox-design {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 15px;
    min-width: 15px;
    height: 15px;
    background-color: $subtle-gray;

    &.isDisabled {
      svg {
        fill: $red;
      }
    }
  }

  &:hover {
    span {
      color: $green;
    }

    .checkbox-design {
      svg {
        fill: $green !important;
      }
    }
  }

  .propertiesCheckbox {
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-right: 5px;
  }

  .hidden {
    display: none;
  }

  .checkbox-label {
    display: none;
  }
}
