.card-container {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  background-color: white;
  padding: 10px 15px;
  width: fit-content;
  height: auto;
  border-radius: 7px;
  width: 100%;
}

.card-container-large {
  padding-bottom: 15px;
}
