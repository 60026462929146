
.tr {
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid #d8d8d8;

    &:last-child {
        border-bottom: 1px solid #d8d8d8;
    }
}

/* VendorCell */
@mixin tableHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 93px;
    width: 143px;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.tableHeaderSelf {
    background-color: #01a699;
    color: #fff;
    @include tableHeader;

    div {
        color: #fff !important;
    }
}

.tableHeaderOthers {
    background-color: #edefee;
    color: #9B9B9B;
    @include tableHeader;
}

/* ProductCell */
.productWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 1267px;
    overflow: hidden;

    .productAnimation {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        position: relative;
        transition: left .5s ease;
    }

    .noProducts {
        width: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .productItem {
        display: flex;
        justify-self: flex-start;
        position: relative;
        cursor: pointer;
        width: 148px;
        margin-left: 16px;
        margin-right: 16px;
        $badgeSize: 20px;
        position: relative;

        .productAnimation {
            position: absolute;
            transition: left .5s ease;
        }
    
        .badge {
            z-index: 2;
            width: $badgeSize;
            height: $badgeSize;
            border-radius: $badgeSize;
            background-color: #01a699;
            color: #ffffff;
            font-family: "Nunito Sans";
            font-size: 11px;
            font-weight: 400;
            text-align: center;
            line-height: $badgeSize;
            position: absolute;
            left: -6px;
            top: -5px;
        }
    
        .colImg, img {
            width: 50px;
            height: 65px; 
        }
    
        .colInfo {
            margin-left: 4px;
            width: 100px;
    
            @mixin ellipsis {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
    
            .colBrand {
                margin-top: 3px;
                color: #666666;
                font-family: "Nunito Sans";
                font-size: 11px;
                font-weight: 400;
                text-transform: uppercase;
                @include ellipsis();
            }
    
            .colName {
                margin-top: 3px;
                color: #666666;
                font-family: "Nunito Sans";
                font-size: 12px;
                font-weight: 400;
                @include ellipsis();
            }
    
            .productPrice {
                margin-top: -2px;
                display: flex;
                align-items: center;
                height: 25px;
    
                span {
                    font-family: "Nunito Sans";
                    font-size: 11px;
                    font-weight: 400;
                }
    
                .arrowIcon {
                    margin: 0px 2px 3px 3px;
                    display: inline-block;
    
                    svg {
                        width: 11px;
                        height: 11px;
                        position: relative;
                        top: 1px;
                    }
                }
    
                .currency {
                    margin-left: 2px;
                }
            }
        }
    }
}

.tableArrow {
    width: 70px;
    height: 100%;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    display: flex;
    justify-content: center;
    align-items: center;

    .arrowWrap {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;

        .arrowTip {
            display: none;
            width: 130px;
            background-color: #fff;
            border: 1px solid #f1f1f1;
            padding: 10px;
            border-radius: 6px;
            position: absolute;
            bottom: calc(100% + 10px);
            box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;;
            font-size: 12px;
            color: #666;
            font-family: "Nunito Sans";

            &::after {
                position: absolute;
                left: 50%;
                top: 100%;
                margin-left: -10px;  /* 为父容器内边距的值 */
                content: ' ';
                border-top: 10px solid #fff;
                border-right: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 10px solid transparent;
            }
        }

        &:hover {
            background-color: #F1F1F1;

            .arrowTip {
                display: block;
            }
        }
    }

    .arrowRight {
        transform: rotate(270deg);
    }

    .arrowLeft {
        transform: rotate(90deg);
    }
}