@import '~global/styles';

.link {
  text-decoration: none;

  &:visited,
  &:active,
  &:hover {
    color: initial;
  }
}
