@import '~global/styles';

.caret {
  transition: transform 0.3s;
  pointer-events: none;
}

.flipped {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
