.formItem {
    display: flex;
    flex-direction: column;
}

.label {
    color: #666;
    font-size: 10px;
    height: 16px;
    margin-bottom: 4px;
    padding-left: 16px;
    text-align: left;
    text-transform: uppercase;
}

.frequencyLabel {
    display: inline-flex;
    align-items: center;
    gap: 6px;

    & > svg {
        width: 16px;
        height: 16px;
        fill: #666;
    }
}

.input {
    width: 140px !important;
    height: 36px !important;
    border: solid 1px #999 !important;
    border-radius: 18px !important;
    padding-left: 16px !important;

    &:not(:placeholder-shown) {
        border: solid 1px #01a699 !important;
    }
}

.emailInput {
    width: 200px !important;
}

