@import '~global/styles';

.caret {
  width: 25px;
  fill: $text-dark;
  pointer-events: none;

  &.reverse {
    transform: rotate(180deg);
  }

  &.clickable {
    pointer-events: auto;
    cursor: pointer;
  }
}
