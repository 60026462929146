.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
}

.material-filter {
  width: 330px;
  background: #FFFEFE;
  box-shadow: 0px 2px 52px 8px rgba(0, 0, 0, 0.3), 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  display: flex;
  padding:  40px 50px 15px;
  color: #666;
  font-size: 14px;
  position: fixed;

  left: 390px;
  top: 200px;
  z-index: 99999;
.btn{
  margin:0px auto;
}
  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
   

    .input-content {
      display: flex;
      flex-direction: row;
      width: 196px;
      height: 48px;
      background: #FFFFFF;
      border-radius: 5px;
      border: 1px solid #11AB9F;
      margin-top: 13px;
      margin-bottom: 20px;


      & > * {
        padding: 0px;
        min-width: 48px;
        background: transparent;
        border: none;

        &:first-child {
          border-right: 1px solid rgba(151, 151, 151, 0.24);
          font-size: 10px;
          flex: 1;
          padding: 5px 13px;

          input:first-child {
            font-size: 22px;
            display: block;
            width: 40px;
            border: none;
            appearance: none;
            padding: 0;
            line-height: 1;
            height: 22px;
            outline: none;
            color: #03A69A;
          }
          input:focus{
            outline: none;
            appearance: none;
          }

          & > *:last-child {
            justify-content: space-between;
            align-items: center;
            display: flex;
            font-size: 18px;
            color: #03A69A;
            line-height: 1;
          }
        }

        &:nth-child(2) {

          display: block;
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          background: transparent;

          svg {
            transform: rotate(90deg);

            path {
              fill: #11AB9F;
            }
          }


        }

        &:last-child {
          border-left: 1px solid rgba(151, 151, 151, 0.24);

          display: block;
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          background: transparent;

          svg {
            transform: rotate(-90deg);

            path {
              fill: #11AB9F;
            }

          }


        }
      }

      &.red {
        border: 1px solid #EC7765;

        & > div {
          &:first-child {
            input{
              color: #EC7765;
            }
            & > div:last-child {
              color: #EC7765;
            }
          }


        }
        button {
          svg {
            path {
              fill: #EC7765;
            }
          }
        }
      }
    }
  }
}
.radio {
  width: 17px;
  height: 16px;
  margin-right: 5px;
  display: block;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  border: 1px solid #A0A0A0;

  &.active {

    border: 1px solid #01A699;

    div {
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background: #01A699;
    }
  }

}
