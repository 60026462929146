$treeItemWidth: 170px;
$treeItemPadding: 0px 15px;

.treeItem {
    background-color: #fff;
    min-width: $treeItemWidth;
    width: auto;
    position: relative;
    padding: $treeItemPadding;
    user-select: none;
    z-index: 9999;

    .treeItemChildren {
        background-color: #fff;
        width: auto;
        min-width: $treeItemWidth;
        padding-top: 10px;
        padding-bottom: 10px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    }

    &:hover {
        background-color: #e6eaea!important;
    }
}

.border {
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}