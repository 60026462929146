@import '~global/styles';

@mixin baseDatePickerSelector {
  text-align: center;
  border: 1px solid $green;
  color: $green;
  cursor: pointer;
  border-radius: 40px;
  outline: none;
  height: 36px;
  font-size: 14px;
  background: transparent;
  min-width: 170px;
  padding: 0 10px;
}

.datePickerSelector {
  @include baseDatePickerSelector();
  width: 164px;
  &.disabled{
    border: 1px solid #cccccc;
    color: #ccc;

  }
}

.dateRangePickerSelector {
  @include baseDatePickerSelector();
  width: 200px;
}

.resetButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 15px !important;

  > button {
    border: none;
    background: $green !important;
    color: $white;
    padding: 10px 20px;
    border-radius: 10px;
  }
}
