.moduleTitle {
    .category {
        color: #666666;
        font-family: "Nunito Sans";
        font-size: 11px;
        font-weight: 400;
        user-select: none;
    }

    .titleWrapper {
        display: flex;
        align-items: center;
        &>svg {
            width: 18px;
            height: 18px;
            opacity: 0.26788038;
            position: relative;
        }
    }

    .title {
        color: #666666;
        font-family: "Nunito Sans";
        font-size: 20px;
        font-weight: 400;
    }

    .subTitle {
        color: #999999;
        font-family: "Nunito Sans";
        font-size: 12px;
        font-weight: 400;
    }
}