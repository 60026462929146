@import '~global/styles';

.actions {
  display: flex;
  top: 4px;
  left: 8px;
  z-index: 9;

  button {
    border-radius: 0;
    height: 18px;
    width: 18px;
    padding: 0;
    margin: 0;

    > div > svg {
      fill: $white;
    }
  }
  .plus {
    background-color: $green;
  }
  .check {
    border-radius:4px;
    background-color: $orange;
    cursor: default;
  }
}
