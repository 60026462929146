$wrapperHeight: 150px;
$actionHeight: 24px;

.wrapper {
    width: 100%;
    max-height: $wrapperHeight;
    min-height: $wrapperHeight;
    padding-inline: 4px;
    font-size: 14px;
    position: relative;

    .inputDiv {
        width: 100%;
        height: calc(100% - $actionHeight);
        border: 1px solid #ddd;
        border-radius: 4px;
        outline: none;
        padding: 4px;
        overflow-y: auto;
        overflow-x: hidden;
        resize: none;
        color: #333;

        &:empty::before {
            content: attr(data-placeholder);
            color: #ccc;
        }

        &:focus {
            content: none;
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: $actionHeight;
        
        .loadData,
        .sendMessage {
            color: #01A699;
            cursor: pointer;
        }
    }
}

.loadDataSpan {
    color: #01A699;
    cursor: pointer;

    .loadDataDiv {
        padding: 4px;
        position: absolute;
        width: 100%;
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
        bottom: 100%;
        left: 0%;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        z-index: 9999992;
        display: none;
    }

    &:hover {
        .loadDataDiv {
            display: block;
        }
    }
}

.loadDataDisabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}

.descData {
    color: #333;
}
