@import '~global/styles';

@mixin titleStyles() {
  border-bottom: 1px solid #223648;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.productSizes {
  .title {
    @include titleStyles;
  }

  .sizes {
    .sizeAvailable {
      display: inline-block;
      padding-top: 2.2px;
      padding-inline: 6px;
      border: 1px solid #223648;
      border-radius: 3px;
      margin-right: 6px;
      margin-block: 3px;
      width: 55px;
      height: 30px;
      text-align: center;
      cursor: default;
    }
  }
}
