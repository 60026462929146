.wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 11px;

    .vendorName {
        padding-top: 0 !important;
        font-size: 14px;
        font-weight: bold;
        color: #666;
        line-height: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .tooltip {
        height: 17px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 4px;
    }
}