.wrapper {
    margin-bottom: 34px;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 102;

    .tooltip {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}