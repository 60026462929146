// Font Families
$font-family: 'Nunito Sans', sans-serif;

// Typography
$font-size-xxxl: 32px;
$font-size-xxl: 21px;
$font-size-xl: 18px;
$font-size-large: 16px;
$font-size-medium: 14px;
$font-size-small: 12px;
$font-size-xs: 11px;
$font-size-xxs: 10px;

// Font Weight
$font-weight-regular: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

// Colors
$primary-color: #01A699;
$white: #ffffff;
$black: #333333;
$tundoraBlack: #484848;
$green: #01a699;
$green-light: #7fb7b6;
$green-bg: #74b6b6;
$red: #f44f11;
$text-dark: #666666;
$text-light: #999999;
$notifications-border: #979797;
$background-gray: #fbfafa;
$search-bar-background: #f9f9f9;
$subtle-gray: #eeeeee;
$scrollbar-thumb: #cecece;
$filter-border: #cccccc;
$filter-border-disabled: #eee;
$body-background: #f8f7f5;
$hover-background: #F1F1F1;
$markdown-footer-lighter: #f4f7f7;
$markdown-footer-darker: #edefee;
$price-ladder-background: #e9e9e9;
$error: #fce8e7;
$warning: #ffeeba;
$success: #d4edda;
$error-text: #d62310;
$warning-text: #856404;
$success-text: #155724;
$orange: #ec7765;
$orange-hover: #eb6d5a;
$crimsonRed: #e22f21;
$notifications-title: #f6650b;
$markdown-bar: #bfe9e5;
$price-ladder-footer: #92b3b2;
$price-ladder-border: #d8d8d8;
$primary-button-border: #a0a0a0;
$border-grey: #979797;
$icon-grey: #a0a0a0;
$title-grey: #717171;
$create-collection-background: #f4f4f4;
$light-orange: #fde7df;
$disabled-orange: #fccebd;
$price-ladder-range: #1bbcb1;
$alertsInputBackground: #efeeee;
$comparisonCard: #649392;
// 表格可点击的单元格鼠标悬浮显示背景颜色
$cell-hover-background: #e6eaea;

$purple: #93648a;
$bluish: #6c6493;
$yellow: #f9bb33;

$border-bottom:rgba(151, 151, 151, 0.34);

// page main content width
$main-content-width:80%;
$main-content-width-90:90%;
