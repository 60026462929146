@import '~global/styles';

$headerFirstRowHeight: 50px;
$headerSecondRowHeight: 40px;

$firstColumnWidth: 160px;
$itemHeight: 48px;
$spaceHeight: 12px;

$currentValueWidth: 150px;
$comparisonValueWidth: 74px;
$changeWidth: 86px;
$vendorWidth: $currentValueWidth + $comparisonValueWidth + $changeWidth;

$py: 6px;
$px: 6px;
$pl: 12px;
$pt: 6px;
$pb: 6px;

$headerPt: $pt + 10px;

$bgColor: rgb(216, 216, 216);
$normalBorderColor: rgb(216, 216, 216);

.title2{
  padding-top:34px;
  font-family: 'Nunito Sans';

  font-size: 16px;
  color: #666666;
  letter-spacing: -0.12px;
}

.deepBorderRight {
  border-right: 1px solid $primary-color;
}

.colorPrimary {
  color: $primary-color;
}

.firstVendor {
  position: sticky;
  left: $firstColumnWidth;
  background-color: #fff;
  z-index: 3;
}
