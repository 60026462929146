.wrapper {
    z-index: 9999991;
    position: fixed;
    left: calc((100vw - 80%) / 2);
    top: 90px;
    background-color: #ffffff;
    border-radius: 19px;
    box-shadow: 0px 2px 32px 0 rgba(0,0,0,.23);
    box-sizing: border-box;
    padding-bottom: 35px;
    resize: both;
    overflow: hidden;

    .mask {
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        background-color: transparent;
        z-index: 0;
    }
    
    .header {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 20px;
        cursor: move;
        position: relative;
        z-index: 1;

        .iconWrapper {
            width: 24px;
            height: 24px;
            cursor: pointer;
            position: relative;
            z-index: 1;
            top: 4px;
            display: flex;
            justify-content: center;
            align-items: center;

            & > svg {
                cursor: pointer;
            }
        }
    }

    .left {
        width: 30px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        cursor: move;
        z-index: 2;
    }

    .bottom {
        height: 30px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        cursor: move;
        z-index: 2;
    }

    .body {
        width: 100%;
        height: 100%;
        padding-left: 60px;
        padding-right: 60px;
        position: relative;
        z-index: 1;
        overflow: hidden;

        .form {
            display: flex;
            flex-direction: row;
            gap: 20px;
            align-items: flex-end;
            margin-bottom: 20px;
            position: relative;
            z-index: 99999;
        }
    }
}
