@import '~global/styles';
@import '../../../styles';
.releaseIcon{
  *{
    fill:#999;
  }
  svg{
    fill:#999999;
  }
}
.release {
  width: calc(100vw - 68px);
  height: 100vh;
  position: fixed;
  opacity: 0;
    top: 0;
    left: 86px;
    z-index: 999999;

  .closeIconWrapper {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 10000;
  }
  
  .contenta {
    width: 641px;
    height: 707.88px;
    border: solid 1px rgba(151, 151, 151, 0.14);
    background: #fbfbfb;
    box-shadow: 0px 2px 28px 4px rgba(0, 0, 0, 0.15);
    z-index: 9999;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding-left: 50px;
    padding-top: 50px;
    padding-right: 50px;
    margin-left: calc((100vw - 719px)/2 + 68px);

  }
  .content {
    width: 641px;
    height: 707.88px;
    border: solid 1px rgba(151, 151, 151, 0.14);
    background: #fbfbfb;
    box-shadow: 0px 2px 28px 4px rgba(0, 0, 0, 0.15);
    z-index: 9999;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding-left: 50px;
    padding-top: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    .header {
      .title {
        height: 18px;
        color: #ec7765;
        font-family: "NunitoSans-Regular";
        font-size: 22px;
        font-weight: 400;
        line-height: 18px;
      }

      .time {
        color: #4a4a4a;
        font-family: "NunitoSans-Regular";
        font-size: 14px;
        font-weight: 400;
        margin-top: 3px;
      }
    }

    .divideTopLine {
      height: 1px;
      width: 100%;
      background-color: rgba(151, 151, 151, 0.33);
      margin: 20px 0;
    }

    .body {
      flex: 1;
      overflow: auto;
    }

    .divideBottomLine {
      height: 1px;
      width: 100%;
      background-color: rgba(151, 151, 151, 0.33);
      margin: 30px 0;
    }

    .description {
      color: #666666;
      font-family: "NunitoSans-Regular";
      font-size: 10px;
      font-weight: 400;
    }

    .releaseList {


      .title {
        color: #4a4a4a;
        font-family: "NunitoSans-Regular";
        font-size: 14px;
        font-weight: 400;
        margin-top: 20px;
      }

      ul {
        li {
          margin-top: 15px;
          color: #666666;
          font-family: "NunitoSans-Regular";
          font-size: 11px;
          font-weight: 400;
        }
      }
    }

  }
  .download{
    position: absolute;
    bottom: 15px;
    text-align: center;
    left:225px;
    z-index: 10000;

  }
}
.account-initials {
  background: $text-light;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-transform: uppercase;
  color: $white;
  font-size: $font-size-large;
  @include flex-center;
}

.account-initials-arrow {
  margin-left: 7.5px;
  display: flex;
  width: 24px;

  svg {
    height: 30px;
    fill: $text-light;
  }
}

.licenseContainer{
  max-height:300px;
  overflow:auto;
  flex-direction:column;
  align-items: flex-start;
  padding:0 !important;
  &>div{
    padding: 10px ;
    display: flex;
    align-items: center;
    color: $text-light;

    >svg {
      margin-right: 15px;
      fill: $text-light;
    }
    &:hover,&.active{
      color: $green;
      svg {
        color: $green;
        fill: $green;
      }
    }

  }
}

