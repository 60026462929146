@import '~global/styles';

.modal {
  // position: fixed;
  // top: 140px;
  // left: 50%;
  background: $white;
  min-height: 300px;
  min-width: 480px;
  z-index: 12;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  // transform: translateX(-50%);
  border-radius: 3px;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $filter-border;
    height: 40px;
    padding-left: 20px;
    justify-content: space-between;

    svg {
      width: 26px;
      height: 26px;
      margin: 0 10px;
    }
  }

  .body {
    width: 90%;
    margin: 0 auto;
    padding-top: 10px;
    flex: 1;

    .tabs{
      button{
        width: 100px;
        min-width: 80px;
      }
    }

    &>div:not(:first-child){
      padding:10px;
    }
    &>label{flex: 1;}
    .row-item > *:not(:last-child){
      margin-right: 10px !important;
    }
    
    button {
      input {
        border: none;
        width: inherit;
        outline: none;
        color: $green;
      }

      &:last-child {
        > div {
          &:first-child {
            width: 20px;
            height: 20px;
            display: flex;
            border-radius: 50%;
            position: relative;
            top: inherit;

           
          }
        }
      }
    }

    .selectedProblem {
      border: 1px solid $green;
      color: $green;

      &:not(:last-child) {
        svg {
          fill: $green;
        }
      }
    }
  }

  .footer {
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    align-items: center;
    flex-direction: column;
    .errorInfo{
      font-size: 12px;
      color:red;
      margin-bottom: 10px;
    }
  }
 

  button {
    width: 150px;
    // margin-bottom: 15px;
    position: relative;

    div {
      &:last-child {
        width: 92px;
      }
    }
  }

  .selectedInput {
    width: 300px;
    margin-bottom: 15px;
    position: relative;

    div {
      &:last-child {
        width: 257px;
      }
    }
  }

  .unfocusedInput {
    cursor: pointer;
  }

}

