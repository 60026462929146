.perVendorContent {
    margin-top: 20px;
    margin-bottom: 60px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;

    &>div:nth-child(3n+3) {
        border-right: none;
    }

    &>div {
        margin-top: 40px;
        border-right: 2px solid rgba($color: #d8d8d8, $alpha: 1);
        width: 33.33%;
        height: 850px;
        position: relative;

        // &:last-child{
        //   border-right: none;
        // }
        .pieTitle {
            text-align: center;

            &>div:first-child {
                color: #484848;
                font-family: "Nunito Sans";
                font-size: 14px;
                font-weight: 400;
            }

            &>div:last-child {
                color: #74b6b6;
                font-family: "Nunito Sans";
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
}

.filterGraphPie {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    // 导出图片功能 html2canvas 兼容处理：需要加相对定位
    position: relative;
}