@import '~global/styles';

.category {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    .categoryHeader {
        flex-shrink: 0;
        width: 260px;
        border-right: 1px solid #edefee;
        display: flex;
        flex-direction: column;
        position: sticky;
        left: 0;
        background-color: $body-background;
        z-index: 3;

        .categoryName {
            height: 54px;
            line-height: 54px;
            background-color: #fff;
            text-align: left;
            padding-left: 40px;
            font-family: Nunito Sans,sans-serif;
            font-size: 16px;
            color: #666;
        }

        .categoryLoadBtn {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-end;
            padding-bottom: 12px;
            padding-left: 40px;
        }
    }

    .vendorFirst {
        position: sticky;
        left: 260px;
        background-color: $body-background;
        z-index: 2 !important;
        box-shadow: 3px 0px 10px 0 rgba(0,0,0,0.04);
    }

    .vendorOthers {
        position: relative;
    }

    .vendor {
        width: 260px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 1px solid #edefee;

        .noData {
            height: 300px;
            font-family: Nunito Sans,sans-serif;
            font-size: 14px;
            color: #999;
            margin-top: 10px;
        }

        .vendorHeader {
            width: 260px;
            height: 54px;
            background-color: #fff;
            border-right: 1px solid #edefee;
        }

        .vendorProduct {
            margin-bottom: 20px;
        }
    }
}
