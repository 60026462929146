.popoverWrap {
    position: fixed;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;

    .popoverArrow {
        position: absolute;
        width: 10px;
        height: 10px;
        box-shadow: -2px -2px 5px #0000000f;;
        background-color: #fff;
        transform: translateY(6px) rotate(45deg);
    }

    .arrowCenter {
        left: 50%;
        margin-left: -8px;
        bottom: 100%;
    }

    .arrowLeft {
        left: 10px;
        bottom: 100%;
    }

    .arrowRight {
        right: 10px;
        bottom: 100%;
    }

    .popoverContent {
        padding: 12px 16px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
    }
}