.competitorTreeSelect {
    & > div[class*=base-select_select ] {
        width: 120px !important;
    }
}

.settingTreeSelect {
    & > div[class*=base-select_select ] {
        width: 120px !important;
    }
}