@import '~pages/PriceArchitecture/styles.module.scss';

.vendorsWrapper {
    position: sticky;
    z-index: 111;
    background-color: #fff;
}

.vendors {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: #fff;

    .firstColumnWrapper {
        width: 160px;
        min-width: 160px;
        position: sticky;
        left: 0;
        z-index: 10;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .priceRange {
            height: 38px;
            line-height: 38px;
            font-weight: bold;
            font-size: 14px;
            padding-left: $firstColumnPadLeft;
        }
    }
}

.shadow {
    position: sticky;
    top: -28px;
    height: 162px;
    margin-top: -170px;
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    z-index: 11;
}
