.wrapper {
    .titleWrapper {
        width: 280px;
        height: 24px;
        font-size: 10px;
        color: #666;
        display: flex;
        flex-direction: row;
        margin-bottom: 4px;

        .periodTitle, .compareToTitle {
            width: 140px;
            padding-left: 35px;
        }
    }

    .date {
        border-radius: 18px;
        height: 36px;
        border: 1px solid #CCCCCC;
        display: flex;
        width: 280px;
        margin: 0 auto;
        cursor: auto;
    
        & > div {
            width: 50%;
            text-align: center;
        }
    
        [class*="dropdownExpanded"] {
            line-height: initial;
        }
        
        .timePeriodSelect[class*="_select_"] {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border: none !important;
            width: 140px;
        }
        
        .compareToSelect[class*="_select_"] {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border: none !important;
            border-left: 1px solid #CCCCCC !important;
            width: 140px;
        }

        .timePeriodSelect,
        .compareToSelect {
            div[class*=selector] {
                border: 1px solid transparent;
            }

            div[class*="selectorArrow"] > svg {
                transform: scale(1.0) !important;
            }
        }
    }
}
