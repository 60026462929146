$messageListHeight: 550px;
$chatBoxWidth: 1000px;

.chatBoxWrapper {
    width: $chatBoxWidth;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    position: fixed;
    inset-inline-end: 74px;
    inset-block-end: 130px;
    z-index: 9999992;
    background-color: #fff;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.messageList {
    min-height: $messageListHeight;
    max-height: $messageListHeight;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .messageItem {
        max-width: 90%;
        width: fit-content;
        word-wrap: break-word;
        font-size: 14px;
        color: #333;
        background-color: #d9d9d9;
        padding: 4px 6px;
        border-radius: 2px;
    }

    .questionerMessageItem {
        align-self: flex-end;
        background-color: #409eff;
        color: #fff;
        position: relative;
        margin-right: 40px;

        & [class*=loadDataSpan] {
            color: #fff !important;
        }

        &::before {
            content: 'You';
            position: absolute;
            left: calc(100% + 10px);
            top: -2px;
            width: 30px;
            height: 30px;
            background-color: #409eff;
            color: #fff;
            border-radius: 50%;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &::after {
            content: '';
            position: absolute;
            left: 100%;
            top: 5px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px;
            border-color: transparent transparent transparent #409eff;
        }
    }

    .responderMessageItem {
        align-self: flex-start;
        position: relative;
        margin-left: 40px;

        &::before {
            content: 'Bot';
            position: absolute;
            right: calc(100% + 10px);
            top: -2px;
            width: 30px;
            height: 30px;
            background-color: #d9d9d9;
            border-radius: 50%;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &::after {
            content: '';
            position: absolute;
            right: 100%;
            top: 5px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px;
            border-color: transparent #d9d9d9 transparent transparent;
        }
    }
}

.inputWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-block: 6px;
    border-top: 1px solid #f8f8f8;

    .input {
        flex: 1;
        border: none;
        outline: none;
        padding-inline: 10px;
        font-size: 14px;
    }
}