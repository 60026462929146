@import '~global/styles';

.modalWrapper {
  position: fixed;
  background: $white;
  border-radius: 4px;
  padding: 15px;
  cursor: auto;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  z-index: 99999999;
  width: 419px;
  height: 319px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.increaseHeight {
    height: 370px;
  }

  .modalHeader {
    display: flex;
    justify-content: space-between;
    padding: 0 0 5px 0;
    .modalTitle {
      display: flex;
      align-items: center;
    }

    svg {
      height: 24px;
      width: 24px;
    }
  }

  .modalBody {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-bottom: 10px;
    margin-top: 10px;
    overflow: hidden;
    position: relative;

    .imageWrap {
      height: 130px;
      width: 100%;
      background-color: #fff;
      overflow: hidden;
    }

    .modalContent {
      .lookbookName {
        height: 45px;

        input[type=text] {
          background-color: #FAFAF9;
          padding: 0 15px;
          height: 45px;
          border: 1px dashed #EC7765;
          box-sizing: border-box;
          border-radius: 4px;
        }
      }
      .lookbookDesc {
        height: 120px;
        margin-top: 20px;
        
        textarea {
          background-color: #FAFAF9;
          border: 1px dashed #EC7765;
          box-sizing: border-box;
          border-radius: 4px;
          padding: 15px;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .modalFooter {
    padding: 15px 15px 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      width: 49%;
      &:last-child {
        margin-left: 5px;
      }
    }
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 7;
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
}

.modalContainer {
  justify-content: center;
  position: relative;
  z-index: 8;
}
