@import '~global/styles';

.productPrice {
  display: flex;
  align-items: center;
  height: 25px;

  .arrowIcon {
    margin: 0px 2px 3px 3px;
    display: inline-block;

    svg {
      width: 11px;
      height: 11px;
      position: relative;
      top: 1px;
    }
  }

  .currency {
    margin-left: 2px;
  }
}

.priceRange {
  svg {
    width: 11px;
    height: 11px;
  }
}