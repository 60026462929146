.metrics {
  border-bottom: 1px solid #d8d8d8;
  border-right: 1px solid rgb(1, 166, 153);
  width: 100%;
  padding-left: 12px;
  padding-right: 26px;

  & > div {
    width: 80%;
    margin-left: 10%;
    position: relative;
    font-size: 12px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    .tangleLeft{
      height: 7px;
      background: #B9DCDD;
      position: absolute;
    }

    .matrics-label {
      position: absolute;
      vertical-align: top;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      line-height: 1;
      bottom: 27px;

      &:before {
        color:#74B6B6;
        content: " ";
        position: absolute;
        border-right: 2px dashed #74B6B6;
        height: 22px;
        left: 0;
      }
      &.min-label {
        color:#74B6B6;
        padding-right: 5px;
        &:before {
          display:none;
        }
        &:after {
          color:#74B6B6;
          content: " ";
          position: absolute;
          border-right: 2px dashed #74B6B6;
          height: 22px;
          right: 2px;
        }
      }
      &.max-label {
        color:#74B6B6;
        padding-left: 5px;
        &:before {
          border-left: 2px dashed #74B6B6;
          border-right: none;
          left: 2px;
        }
      }
      &.avg-label {
        color:#EC7765;
        display: flex;
        line-height: 0.8;
        top: 40px;
        justify-content:  flex-end;
        align-items: flex-end;
        padding-left: 5px;
        &:before {
          border-left: 2px dashed #EC7765;
          border-right: none;
        }
      }
      &.median-label {
        color:#6C6493;
        display: flex;
        line-height: 1;
        top: 22px;
        justify-content:  flex-end;
        align-items: flex-end;
        padding-left: 5px;
        &:before {
          border-left: 2px dashed #6C6493;
          border-right: none;
        }
      }
    }
  }
  .tangleLeft {
    &:before {
      content: " ";
      width: 0;
      height: 0;
      border-top: 4px solid rgba(0, 0, 0, 0);
      border-bottom: 4px solid rgba(0, 0, 0, 0);
      border-right: 5px solid rgba(1, 166, 153, 1);
      position: absolute;
      left: -16px;
      top: -1px;
      border-left: 5px solid rgba(0, 0, 0, 0);
    }

    &:after {
      content: " ";
      width: 0;
      height: 0;
      border-top: 4px solid rgba(0, 0, 0, 0);
      border-bottom: 4px solid rgba(0, 0, 0, 0);
      border-left: 5px solid rgba(1, 166, 153, 1);
      position: absolute;
      right: -11px;
      top: -1px;
      border-rigth: 5px solid rgba(0, 0, 0, 0);
    }


  }

}
