@import '~global/styles';

.text {
  color: $text-dark;
  font-size: $font-size-medium;
  font-family: $font-family;

  &.small {
    font-size: $font-size-small;
  }

  &.medium {
    font-size: $font-size-medium;
  }

  &.large {
    font-size: $font-size-large;
  }

  &.xl {
    font-size: $font-size-xl;
  }

  &.xxl {
    font-size: $font-size-xxl;
  }

  &.xs {
    font-size: $font-size-xs;
  }

  &.xxs {
    font-size: $font-size-xxs;
  }

  &.onHoverChangeColor {
    &:hover {
      color: $green
    }
  }

  &.primary {
    color: $red;
  }

  &.secondary {
    color: $text-light;
    a,
    a:visited,
    a:hover {
      color: $text-light;
    }
  }

  &.disabled {
    color: $filter-border;
    a,
    a:visited,
    a:hover {
      color: $filter-border;
    }
  }

  &.accent {
    color: $green;
  }

  &.regular {
    font-weight: $font-weight-regular;
  }
  &.semibold {
    font-weight: $font-weight-semi-bold;
  }
  &.bold {
    font-weight: $font-weight-bold;
  }
  &.extrabold {
    font-weight: $font-weight-extra-bold;
  }
  &.extrablack {
    font-weight: $font-weight-black;
  }

  &.oneLine {
    display: block;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.isLoading {
    position: relative;

    &:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: top;
      -webkit-animation: ellipsis steps(5, end) 1500ms infinite;
      animation: ellipsis steps(5, end) 1500ms infinite;
      content: '\2026'; /* ascii code for the ellipsis character */
      width: 0px;
      position: absolute;
      padding-left: 3px;
      bottom: -1px;
      font-size: 18px;
    }

    &.withoutText {
      &:after {
        left: -13px;
      }
    }
  }

  &.underline {
    text-decoration: underline;
  }

  &.strikeThrough {
    text-decoration: line-through;
  }
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}
