.wrapper {
    display: flex;
    position: relative;
}

.body {
    padding: 20px 10px;
    display: flex;
    flex-direction: row;
    margin-top: 45px;

    .moduleTitle {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        & > div:first-child {
            font-family: Nunito Sans,sans-serif;
            font-size: 16px;
            line-height: 19px;
            color: #000;
            margin-right: 20px;
        }

        svg {
            fill: #01A699;
            opacity: 1;
        }

        & > div:last-child {
            font-family: Nunito Sans,sans-serif;
            font-size: 11px;
            color: #01A699;
        }
    }

    .reportProduct {
      margin-top: 30px;
    }
}
