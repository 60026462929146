.search {
    display: flex;
    align-items: flex-end;

    & > div {
        width: 140px;
    }
}

.vendorWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-bottom: 50px;
}

.popup {
    padding: 6px 14px 14px;

    .time {
        font-size: 14px;
        line-height: 14px;
        color: #4B4B4B;
        text-transform: uppercase;
    }

    .title {
        font-size: 12px;
        line-height: 18px;
        padding-left: 120px;
    }

    .content {
        display: flex;
        font-size: 12px;
        line-height: 18px;

        & > div:first-child {
            width: 120px;
        }
    }
}

.productModalTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding-right: 10px;
  width: 100%;
  
  .productTitle {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}