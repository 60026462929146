@import '~global/styles';

.button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0 20px;
  height: 36px;
  text-align: center;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  &[disabled] {
    cursor: not-allowed !important;
  }

  &:hover {
    cursor: pointer;
  }

  .content {
    display: flex;
    align-items: center;

    // &.disabled {
    //   color: $text-light;
    // }
  }

  &.withIcon {
    .icon {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      vertical-align: middle;
      top: -1px;
      position: relative;
      display: inline-block;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &.onlyIcon {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      .icon {
        margin-right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0px;
      }

      .content {
        display: none;
      }

      &.mini {
        width: 24px;
        height: 24px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
      }

      &.miniLarge {
        width: 36px;
        height: 36px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
        .icon {
          left: 50%;
          margin-left: -15px;

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }

      &.noShadow {
        box-shadow: none;
      }

      &.hoverShadow {
        &:hover {
          box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
          border-radius: 50%;
        }
      }

      &.rotateIcon45 {
        .icon {
          svg {
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  &:active,&.disabled {
    background: #f5998b;
    cursor: auto;
    .icon {
      svg {
        fill: $text-light;
      }
    }

    &.text {
      opacity: 1;
    }

    opacity: 0.5;
  }

  &.primary {
    color: $white;
    background-color: $orange;

    .icon {
      svg {
        fill: $white;
      }
    }
  }
  &.success {
    color: $white;
    background-color: $green;

    .icon {
      svg {
        fill: $white;
      }
    }
  }
  &.selected {
    color: $white;
    background-color: $crimsonRed;

    .icon {
      svg {
        fill: $white;
      }
    }
  }
  &.secondary {
    color: $text-dark;
    border: 1px solid $primary-button-border;

    .icon {
      svg {
        fill: $primary-button-border;
      }
    }

    &.mini {
      border: none;
      background: $white;
    }
  }
  &.text {
    opacity: 0.8;
    font-size: $font-size-medium;
    letter-spacing: 1.28px;
  }
}
