.descriptions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    text-align: left;
}

.descriptionsItem {
    margin: 0 0 20px 0;

    .descriptionsItemLabel {
        font-family: Nunito Sans, sans-serif;
        font-size: 11px;
        line-height: 18px;
        color: #666;
        text-transform: uppercase;
        white-space: nowrap;
    }

    .descriptionsItemContent {
        font-family: Nunito Sans, sans-serif;
        color: #666;

        .descriptionsItemContentItem {
            width: 100%;
            display: inline-flex;
            justify-content: space-between;

            &>span:last-child {
                width: 50%;
                text-align: right;
                color: #01a699;
                white-space: pre-wrap;
            }
        }
    }

    >span {
        min-height: 70px;
    }
}


.tooltip {
    position: relative;
}

.tooltip::before {
    content: attr(data-tooltip);
    font-size: 12px;
    white-space: nowrap;
    width: fit-content;
    background-color: #fff;
    color: #666;
    text-align: center;
    padding: 10px 16px;
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    transition: display .3s;
    display: none;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15) !important;
    font-family: "Nunito Sans", sans-serif;
    border-radius: 3px;
}

.tooltip::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    transition: display .3s;
    display: none;
    border-color: #fff transparent transparent transparent;
}

.tooltip:hover:before,
.tooltip:hover:after {
    display: block;
}