@import '../styles';

.input {
  padding: $padding;
  width: 100%;
  height: $height;
  background-color: $white;
  border: none;

  &:focus {
    outline: none;
  }
}
