.perVendor {
  .title {
    display: flex;
    align-items: baseline;
    padding-left: 38px;
    position: relative;
    
    &>div:first-child {
      font-size: 14px;
    }
  }

  .subtitle {
    text-transform: uppercase;
    font-size: 10px;
    color: #666;
  }

  .calendar {
    display: flex;
    flex-wrap: wrap;
    width: 418px;
  }
}

.perVendor:nth-child(n + 5) {
  margin-top: 40px;
}