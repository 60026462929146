@import '~global/styles';

.navDate {
    position: relative;
    background-color: white;

    &.disabled {
        cursor: not-allowed;

        input:disabled {
            cursor: not-allowed;

        }
    }

    [class*="Calendar "] {
        width: 320px;
        margin: 0 auto;
        box-shadow: none;
        padding-top: 0px !important;
    }

    .line {
        width: 1px;
        height: 100%;
        background-color: rgba(151, 151, 151, .5);
        margin-left: 10px;
        margin-right: 10px;
    }

    .title {
        color: #666 !important;
        padding-left: 38px !important;
        padding-bottom: 0px !important;
        line-height: initial;
        font-size: 20px;
        font-weight: 400;
    }

    .subtitle {
        font-size: 14px;
        color: #666;
        padding-left: 38px;
        margin-bottom: 20px;
        line-height: initial;
    }

    .date {
        border-radius: 18px;
        height: 36px;
        border: 1px solid #CCCCCC;
        display: flex;
        width: 280px;
        margin: 0 auto;
        cursor: auto;

        & > div {
            width: 50%;
            text-align: center;
        }

        [class*="dropdownExpanded"] {
            line-height: initial;
        }
    }

    .dateLabel {
        color: $primary-color;
        font-size: 14px;
    }
}

.footer {
    cursor: pointer;
    outline: none;
    height: 32px;
    line-height: 32px;
    width: 100%;
    color: #666666;
    font-size: 12px;
    padding-left: 15px;
    border: none;
    text-align: left;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        background-color: #e6eaea !important;
        color: #01a699;
    }
}

.disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: not-allowed;
}

.timePeriodSelect[class*="_select_"] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: none !important;
}

.compareToSelect[class*="_select_"] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: none !important;
    border-left: 1px solid #CCCCCC !important;
}

.btnWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 6px;

    .btn {
        font-size: 14px;
        padding-inline: 12px;
        border-radius: 6px;
        background-color: #01a699;
        color: #fff;
        height: 34px;
        line-height: 34px;
        cursor: pointer;
    }
}