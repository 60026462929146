@import '~global/styles';

.classifyName {
    background-color: #fff;
    padding: 1em;
    line-height: 20px;
    font-family: Nunito Sans,sans-serif;
    font-size: 16px;
    color: #666;
    padding-left: 40px;
}

.body {
    display: flex;
    flex-direction: row;
    padding: 1em;
    min-height: 540px;

    .left {
        flex-basis: 11%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 70px;
        position: relative;

        .options {
            font-family: Nunito Sans,sans-serif;
            font-size: 12px;
            color: #666;
            padding-left: 24px;

            .optionsTotal {
                color: #01a699;
                margin-left: 5px;
            }
        }

        .lessOrMoreBtn {
            margin-top: 50px;
            margin-left: 24px;

            button {
                justify-content: left;
                padding: 0 10px 0 0;
                display: flex;
                border: none;
                background: transparent;
                cursor: pointer;
                height: 36px;
                text-align: center;
                align-items: center;
                margin-left: -5px;
                svg {
                    fill: $green;
                    width: 20px !important;
                }
                span{
                    white-space: nowrap;
                }
            }
        }

        .hiddenLessOrMoreBtn {
            visibility: hidden;
        }
    }
    .expand{
        justify-content: flex-start;
        .lessOrMoreBtn{
            margin-top: 0;
            &>button{
               svg{
                width: 20px !important;
               }
            }
        }
    }

    .right {
        flex: 1;
    }

    .folding {
        height: 540px;
        overflow: hidden;
    }

    .productList {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        padding: 2em 1em 2em;
        // max-width: 1507px;
        // min-height: 585px;

        .productItem {
            flex-basis: 9%;
        }
        [class="ReactVirtualized__Grid__innerScrollContainer"]{min-height: 310px;;}
    }

    .loadMoreBtn {
        display: flex;
        width: 100%;
        padding: 2em 1em 1em;
        justify-content: center;
        font-size: $font-size-small;
        position: relative;
        top: -40px;
    }
}

.noData {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.selectLine{
    position: absolute;
    top: 0px;
    z-index: 1;
    transform: scale(2);
    border-radius: 4px;
    svg{
        fill:#f44f11 !important
    }
}