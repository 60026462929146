@import '~global/styles';
@import '../../layout/styles';

.button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  @include flex-center;
  padding: 0 20px;
  height: 36px;
  text-align: center;
  border-radius: 18px;
  &>div:first-child svg{
    max-width: 100%;
    max-height: 100%;
    width: 20px !important;
        height:  20px !important;
  }
  &:hover {
    cursor: pointer;
  }

  .content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    &.disabled {
      color: $text-light;
    }
  }

  &.withIcon {
    .icon {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      vertical-align: middle;
      top: -1px;
      position: relative;
      display: inline-block;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &.onlyIcon {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      text-align: center;
      @include flex-center;
      padding: 0;

      .icon {
        margin-right: 0px;
        @include flex-center;
        top: 0px;
      }

      .content {
        display: none;
      }

      &.mini {
        width: 24px;
        height: 24px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
      }

      &.miniLarge {
        width: 36px;
        height: 36px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
        .icon {
          left: 50%;
          margin-left: -15px;

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }

      &.noShadow {
        box-shadow: none;
      }

      &.hoverShadow {
        &:hover {
          box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
          border-radius: 50%;
        }
      }

      &.rotateIcon45 {
        .icon {
          svg {
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  &.primary {
    color: $white;
    background-color: $orange;
    
    .icon {
      svg {
        fill: $white;
      }
    }

    &:hover{
      background-color: $orange-hover;
    }
    &:disabled{
      opacity: 0.5;
    }
    &:hover:disabled{
      background-color: $orange-hover;
      opacity: 0.5;
    }
  }
  &.success {
    color: $white;
    background-color: $green;

    .icon {
      svg {
        fill: $white;
      }
    }
  }
  &.selected {
    color: $white;
    background-color: $crimsonRed;

    .icon {
      svg {
        fill: $white;
      }
    }
  }

  &.secondary {
    color: $text-dark;
    border: 1px solid $primary-button-border;

    .icon {
      svg {
        fill: $primary-button-border;
      }
    }

    &.mini {
      border: none;
      background: $white;
    }
  }

  &.text {
    opacity: 0.4;
    font-size: $font-size-medium;
    letter-spacing: 1.28px;
    text-transform: uppercase;
  }

  &:active,&.disabled {
    // background: #f5998b;
    cursor: auto;
    .icon {
      svg {
        fill: $text-light;
      }
    }

    &.mini {
      //background: $white;
    }

    &.text {
      opacity: 1;
    }

    opacity: 0.5;
  }
  .orange{
    background-color: $orange;
    &:active,&.disabled {
      background: #f5998b;
      cursor: auto;
      .icon {
        svg {
          fill: $text-light;
        }
      }
  
      &.mini {
        //background: $white;
      }
  
      &.text {
        opacity: 1;
      }
  
      opacity: 0.5;
    }
  }
  .white{
    background-color: $white;
  }


}

