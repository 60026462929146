@import '~global/styles';
@import '~pages/PriceArchitecture/styles.module.scss';

$rowHeight: 47px;

$lastRegionDistance: 15px;
// 每个 vendor 的第一个 region 宽度
$firstRegionCurrentValueWidth: 140px;
$firstRegionChangeWidth: 170px;
$othersRegionCurrentValueWidth: 78px;
$othersRegionChangeWidth: 65px;

$changeBackgroundColor: #F5F8F8;
$normalBorderColor: rgb(216, 216, 216);

$pl: 10px;

.firstRegionCurrentValue {
    width: $firstRegionCurrentValueWidth;
    height: 100%;
}

.firstRegionChange {
    width: $firstRegionChangeWidth;
    height: 38px;
    line-height: 38px;
}

.vendor {
    color: #666;
    font-size: 14px;
    font-family: "Nunito Sans", sans-serif;

    .vendorLogo {
        height: 76px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding-right: 10px;
        padding-left: $pl;
        cursor: grabbing;
        position: relative;
        left: $pl;
        border-left: 1px solid $normalBorderColor;
        
        .vendorLogoWrapper {
            display: flex;
            max-width: 150px;
            min-height: 50px;
            flex-direction: row;
            align-items: center;
            margin-bottom: 5px;
            img{max-width: 100% !important;max-height: 50px !important; width: auto !important;}

        }

        .vendorPlatformName {
            max-width: 150px;
            height: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
            line-height: 1.5rem;
        }

        .vendorName {
            font-family: Nunito Sans;
            text-align: left;
            color: rgba(0,0,0,.87);
            font-weight: 500;
            font-size: .875rem;
            letter-spacing: .01071em;
            height: 76px;
            line-height: 76px;
            white-space: nowrap;
            text-decoration: none;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flow-root;
            cursor: inherit;
            cursor: grab;
        }

        & > div {
            width: 100%;
        }

        svg {
            max-width: 100%;
            height: 50px;
            transform: scale(1) !important;
        }
    }

    .vendorRegionWrap {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .firstVendor {
        user-select: none;
    }

    .otherRegion {
        width: $othersRegionCurrentValueWidth + $othersRegionChangeWidth;

        .changeColumn {
            padding-left: $pl - 2px !important;
        }
    }
}

.regionWrap {
    display: flex;
    flex-direction: row;
    align-items: center;

    .regionName {
        height: 38px;
        line-height: 38px;
        padding-left: $pl;
        font-weight: bold;
    }
}

.valueItem {
    height: $rowHeight;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.firstValueItem {
    .currentValueColumn {
        width: $firstRegionCurrentValueWidth;
        box-sizing: border-box;
        padding-left: $pl;
    }

    .changeColumn {
        width: $firstRegionChangeWidth;
    }
}

.othersValueItem {
    .currentValueColumn {
        width: $othersRegionCurrentValueWidth;
    }

    .changeColumn {
        width: $othersRegionChangeWidth;
        padding-left: $pl;
    }
}

.otherRegionCurrentValue {
    width: $othersRegionCurrentValueWidth;
    height: 100%;
}

.otherRegionChange {
    width: $othersRegionChangeWidth;
    height: 100%;
}

.lastRegion {
    width: $othersRegionCurrentValueWidth + $othersRegionChangeWidth + $lastRegionDistance !important;
}

.lastRegionDistance {
    width: $lastRegionDistance;
    height: 100%;
}

.currentValueColumn {
    width: $firstRegionCurrentValueWidth;
    height: 100%;
    padding-left: $pl;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.changeColumn {
    width: $firstRegionChangeWidth;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

// 箭头
.arrowWrap {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    &:hover {
        .arrowTip {
            display: block;
        }
    }
}

.arrowRight {
    transform: rotate(270deg);
    position: relative;
    left: -5px;
}

.arrowLeft {
    transform: rotate(90deg);
    position: relative;
    left: -5px;
}

.arrowTop {
    transform: rotate(180deg);
    margin-left: -9px;
}

.arrowBottom {
    transform: rotate(0deg);
    margin-left: -9px;
}

// 第一列, 显示一些 0-10, 10-20, Total 这些内容
.vendorFirstColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #666;
    font-size: 14px;
    font-family: "Nunito Sans", sans-serif;
    width: 160px;
    min-width: 160px;
    user-select: none;
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 2;

    .priceRange {
        height: 56px;
        line-height: 56px;
        font-weight: bold;
    }

    .valueItemWrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: $rowHeight;
        padding-left: $firstColumnPadLeft;
    }

    .priceIndex {
        padding-left: $firstColumnPadLeft;
    }
}

.borderTop {
    border-top: 2px solid rgb(216, 216, 216);
    padding-top: 20px;
    margin-top: 10px;
    box-sizing: content-box;
}

// 
.verticalBorderTop {
    border-top: 1px solid #01A699;
    width: 100%;
}
.verticalBorderBottom {
    border-bottom: 1px solid #01A699;
    margin-bottom: 10px;
    width: 100%;
}
.verticalChildren {
    font-size: 11px;
    background-color: #fff;
}
.firstChildRange {
    padding-top: 10px;
}

.colorPrimary {
    color: $primary-color;
}

.borderBottom {
    border-bottom: 1px solid $normalBorderColor;
}

.deepBorderRight {
    border-right: 1px solid $primary-color;
}

.normalBg {
    background-color: $changeBackgroundColor;
}

.verticalDashedLine {
    position: absolute;
    height: 100%;
    width: 1px;
    left: 50%;
    top: 0;
    background-image: linear-gradient(to bottom, rgb(216, 216, 216) 55%, #fff 0%); /* 35%设置虚线点y轴上的长度 */
    background-position: right; /* top配置上边框位置的虚线 */
    background-size: 1px 10px; /* 第一个参数设置虚线点的间距；第二个参数设置虚线点y轴上的长度 */
    background-repeat: repeat-y;
    transform: translateX(-50%);
    z-index: 0;
}

.verticalDashedLineOfLastRegion,
.lockWrapperOfLastRegion {
    left: calc(50% - $lastRegionDistance / 2) !important;
}

.verticalDashedLineOfFirstRegion,
.lockWrapperOfFirstRegion {
    left: calc(50% - $firstRegionChangeWidth + $firstRegionCurrentValueWidth) !important;
}

.lockWrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #fff;
    z-index: 1;
}
