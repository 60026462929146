@import '~global/styles';

.message {
  padding: 0 10px;
  margin: 10px 0;
  width: fit-content;
  border-radius: 3px;
  display: flex;
}

.errorWrap {
  background-color: $error;
  display: flex;
  align-items: center;

  .error {
    color: $error-text;
    padding: 5px 3px;
  }
}
.warningWrap {
  background-color: $warning;
  .warning {
    color: $warning-text;
  }
}
.successWrap {
  background-color: $success;
  .success {
    color: $success-text;
  }
}
.action {
  font-size: 14px;
  color: $error-text;
  text-decoration: underline;
  cursor: pointer;

  &:hover,
  &:visited,
  &:active {
    color: $error-text;
  }
}

.infoWrap {
  display: flex;
  justify-content: space-between;
  background-color: $white;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  > button {
    padding-right: 0;
    padding-left: 10px;

    > div:nth-of-type(2) {
      color: $red;
    }
  }
}
