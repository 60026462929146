@import '~global/styles';

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 43px;
  left: 0;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  max-height: 800px;
  background: $white;
  z-index: 101;

  &.noBackground {
    background: none;
  }

  .loading-spinner {
    width: 40px;
    height: 40px;
  }
}

.selectCategory {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}


