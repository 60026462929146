@import '~global/styles';

.vendor {
    display: flex;
    flex-direction: row;
    position: relative;

    .vendorPadLeft {
        width: 260px;
        border-right: 1px solid #edefee;
        flex-shrink: 0;
        position: sticky;
        left: 0;
        background-color: $body-background;
        z-index: 10;

        .vendorPadLeftHeader {
            height: 68px;
            background-color: $white;
        }

        .vendorPadLeftContent {
            height: 200px;
        }
    }

    .vendorItemFirst {
        position: sticky;
        left: 260px;
        background-color: $body-background;
        z-index: 2 !important;
        box-shadow: 3px 0px 10px 0 rgba(0,0,0,0.04);
        
        .vendorHeader {
            font-weight: 700;
        }
    }

    .vendorItemOthers {
        position: relative;
        z-index: 1;
    }

    .vendorItem {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: Nunito Sans,sans-serif;
        font-size: 16px;
        color: #666;

        .vendorHeader {
            height: 68px;
            width: 260px;
            background-color: #fff;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px;
            border-right: 1px solid #edefee;

            .vendorHeaderZoom > svg {
                cursor: pointer;
            }

            .vendorHeaderName {
                flex: 1;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .vendorHeaderClose {
                position: relative;
                bottom: 10px;
            }
        }

        .vendorContent {
            width: 260px;
            border-right: 1px solid #edefee;
            height: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .vendorImageWrap {
                width: 70px;
                height: 70px;
                border-radius: 70px;
                overflow: hidden;
                margin-bottom: 20px;
            }

            .vendorPercent {
                margin-bottom: 10px;
                font-family: Nunito Sans,sans-serif;
                font-size: 18px;
                line-height: 18px;
                color: #666;
                display: flex;

                .percentArrow {
                    width: 16px;
                    height: 16px;
                    opacity: 0.8;
                    margin-left: 8px;
                }

                .percentNone {
                    display: none;
                }

                .percentHigher {
                    transform: rotate(0deg);
                }

                .percentLower {
                    transform: rotate(180deg);
                }
            }

            .vendorDesc {
                line-height: 18px;
                font-family: Nunito Sans,sans-serif;
                font-size: 12px;
                display: flex;
                flex-direction: row;
                justify-content: center;

                .vendorLabel {
                    color: #999;
                    margin-right: 8px;
                }

                .vendorValue {
                    color: #01a699;
                    font-size: 12px;
                    font-family: Nunito Sans,sans-serif;
                }
            }
        }
    }   
}

.stickyHeader {
    position: fixed;
    top: 62px;
    display: flex;
    flex-direction: row;
    z-index: 10;
    background-color: #fff;

    .vendorPadLeftHeader {
        width: 260px;
        height: 68px;
        background-color: $white;
        position: sticky;
        left: 0;
        flex-shrink: 0;
    }

    .vendorHeaderFirst {
        position: sticky;
        left: 260px;
        background-color: $body-background;
        z-index: 2 !important;
    }

    .vendorHeaderOthers {
        position: relative;
        z-index: 1;
    }

    .vendorHeader {
        flex-shrink: 0;
        height: 68px;
        width: 260px;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px;

        .vendorHeaderZoom > svg {
            cursor: pointer;
        }

        .vendorHeaderName {
            flex: 1;
            text-align: center;
            font-family: Nunito Sans,sans-serif;
            font-weight: 700;
            font-size: 16px;
            color: #666;
        }

        .vendorHeaderClose {
            position: relative;
            bottom: 10px;
        }
    }
}