@import '~global/styles';

.widgetInfoWrapper {
  position: relative;
  display: inline-block;

  .widgetInfo {
    display: block;
    position: absolute;
    top: 33px;
    left: -71px;
    padding: 0px;
    font-family: inherit;
    font-size: 14px;
    line-height: 19px;
    text-align: left;
    color: #000;
    background-color: #fff;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.0998416);
    border-radius: 4px;
    z-index: 13;

    h4 > span {
      position: absolute;
      content: '';
      display: block;
      top: 0px;
      right: 0px;
      height: 30px;
      width: 30px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
    }
  }

  .infoIcon {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    color: #fff;
    background-color: #ccc;
    border-radius: 50%;
    cursor: default;
  }

  .selectionDetailItem {
    padding: 3px 10px;
    font-size: 12px;
    line-height: 1.2;
    color: $text-light;

    label {
      color: $text-dark;
      font-weight: bold;
    }
  }
}


.actionBox {
  position: absolute;
  z-index: 99;
  background: transparent;

  &:hover {
    display: block;
  }

  .actionBoxPanel {
    color: $green;
    font-size: 12px;
    font-weight: 400;
    z-index: 999;
    background: $white;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.0998416);
    border-radius: 4px;
    padding: 2px 8px;
  }

  .hotArea {
    cursor: pointer;
    background: transprent;
  }

  ul {
    list-style: none;
    padding-bottom: 4px;

    li {
      width: 200px;
      line-height: 32px;
      box-sizing: border-box;
      border-bottom: 1px solid #eeeeee;
      padding: 0px 0px;
      cursor: pointer;
      font-size: 12px;
      font-weight: 400;

      .saveToText {
        font-size: 10px;
        cursor: default;
      }

      div {
        color: #000;
        cursor: default;
      }

      &:last-child {
        border-bottom: transparent;
        display: flex;
      }

      svg {
        fill: $green;
        width: 18px;
        height: 18px;
      }

      .createNewText {
        margin: 1px 0 0 5px;
        color: $green;
      }

      &.removeLookbook {
        svg {
          fill: $orange;
        }

        span {
          color: $green;
        }
      }
    }
  }
}
