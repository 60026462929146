@import '~global/styles';

.close-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  transform: rotate(45deg);

  path {
    fill: $text-light;
  }
}
