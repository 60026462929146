
.left {
    justify-content: start;
}

.center {
    justify-content: center;
}

.right {
    justify-content: end;
}

.tbody {
    .tr {
        display: flex;

        .td {
            padding: 6px 5px;
            font-family: 'Nunito Sans';
            font-size: 14px;
            color: #484848;
            flex-shrink: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;
        }
    }
}