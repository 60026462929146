.priceSpreadDialog {
    padding: 15px 15px 30px;
    border: none !important;
    outline: none !important;

    .wrapper {
        width: 800px;
        height: 100px;
        display: flex;
        flex-direction: column;
        cursor: auto;

        .header {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #666;
            margin-bottom: 15px;

            & > svg {
                transform: scale(1) rotate(45deg);
            }
        }

        .body {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            & > div[class*=metrics] {
                border: none !important;
    
                & > div {
                    width: 85% !important;
                }
            }
        }
    }
}