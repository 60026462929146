.spinWrap {
    position: relative;

    .spinMask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,.8);
    }

    .spinMaskFlex {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .spinMaskSticky {
        text-align: center;
        position: sticky;
        top: 200px;
        padding: 100px 0;
    }
}

.loading-icon-small {
    width: 30px;
    height: 30px;
}

.loading-icon-default {
    width: 40px;
    height: 40px;
}

.loading-icon-large {
    width: 50px;
    height: 50px;
}