.container {
    padding: 6px 10px;
    background-color: #f7f8f9;
    margin-bottom: 20px;

    .title {
        height: 20px;
        display: flex;
        gap: 20px;
        font-size: 14px;
        font-weight: bold;
    }

    .sizeContainer {
        height: 40px;
        display: flex;
        align-items: center;
        gap: 20px;
        font-size: 14px;

        .sizeAi {
            width: 120px;
        }

        .sizeTagContainer {
            margin-right: 20px;
        }

        .productLink,
        .maskChildrenLink {
            cursor: pointer;
            color: #01A699;
        }

        .operator,
        .time {
            color: #888;
            margin-left: 20px;
        }
    }

    .textLink {
        cursor: pointer;
        color: #01A699;
    }
}