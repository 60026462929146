@import '~global/styles';

.productPrice {
  & > * {
    display: inline-block;
  }

  .arrow {
    font-size: $font-size-xxl;
    padding: 0px 10px;
    
    svg {
      height: 18px;
      width: 18px;
      fill: $green;
    }
  }
  .discountedPrice {
    h1 {
      color: $green;
    }
  }
}
