@import '~global/styles';

.qaButtons {
  display: flex;

  button {
    margin: 0 !important;
    padding: 0 5px;
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
      padding-left: 10px;
    }


  }
  [data-testid="dropdown"] button {
    &:first-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  [data-testid="selector"] {
    border: solid 1px transparent !important;
  }

  &>:last-child {
    border-top-right-radius: 18px !important;
    border-bottom-right-radius: 18px !important;
    padding-right: 10px;
  }
}

.lineItem>div {
  display: flex;
  align-items: center;

  button {
    height: 26px;
  }
}

.dropdown [data-testid="dropdown"] > div {
    overflow: visible !important;
    [data-testid="datepicker-input"]{
      z-index: 0 !important;
      border: none;
      cursor: pointer;
    }
    [data-testid="datepicker-input"]+div{
      z-index: 10000 !important;
    }
}