@import '~global/styles';

@mixin textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}
.label{
  font-size: 10px;
  color: #666;
  text-align: left;
  margin-bottom: 4px;
  height: 16px;
  padding-left: 15px;
  text-transform: uppercase;
}
.select {
  width: fit-content;
  width: 120px;
  height: 36px;
  background: transparent;
  font-size: 14px;
  color: $text-dark;
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  outline: none;

  .selector {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    padding-right: 5px;
    border: solid 1px $text-light;
    border-radius: 18px;

    .selectorSelection {
      flex: 1;
      font-size: 14px;
      color: $text-dark;
      cursor: pointer;
      border: none;
      outline: none;
      user-select: none;
      text-align: left;
      @include textEllipsis;
    }

    .selectorArrow {
      display: flex;

      > svg {
        height: 20px;
        fill: $text-dark;
        transition: transform .5s;
      }
    }

    .selectorArrowFlipped {
      > svg {
        transition: transform .5s;
        transform: rotate(180deg);
      }
    }
  }
  
  .dropdown {
    display: none;
    @include textEllipsis();
  }

  .dropdownExpanded {
    display: initial;
    position: absolute;
    top: 50px;
    left: 0;
    border-radius: 4px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;
    color: $text-dark;
    width: max-content;
    max-height: 290px;
    min-width: 164px;
    overflow-y: auto;
    cursor: initial;
    z-index: 1000;
    width:100%;
    text-align: left;
    display: flex;
    flex-direction: column;
  }
}

// header 样式
.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 15px 0;

  .headerTitle {
    flex: 1;
  }
}

// search 样式
.search {
  padding: 10px 15px 0;

  .searchInput {
    outline: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 2px 6px;
    width: 100%;
  }
}

// 鼠标悬浮在下拉选项上时，字体变蓝，不包含 disabled 状态的下拉选项
.optionItem:hover {
  .optionName:not(.disabled) {
    color: $green;
  }
}

// 下拉选项样式
.optionName {
  white-space: nowrap;
  @include textEllipsis();
}

.optionName.active {
  color: $green;
}

.smallDescription {
  color: $text-light;
  font-size: 9px;
  margin-left: 4px;
}

// 超出最大选择数目提示文案样式
.maxCountTip {
  color: red;
  font-size: 13px;
  white-space: pre-wrap;
  padding: 10px 15px 0;
}

// clear all 按钮样式
.clearAll {
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

// ok 按钮样式
.okButton{
  border-radius: 5px;
  display: unset;
  width: 100%;
  display: flex;
  justify-content: center;
}

// 禁用
.disabled {
  color: $text-light;
  background: none;
  cursor: not-allowed;
  opacity: 0.5;

  .selectorSelection {
    cursor: not-allowed !important;
  }
}

// 被选中
.green,
.isSelected:not(.disabled) {
  border: solid 1px $green;
  
  .selectorSelection {
    color: $green;
  }

  .selectorArrow {
    > svg {
      fill: $green;
    }
  }
}