$layout-padding: 1em;
$sidebar-category-padding: 0.5em 1em;

@mixin fill-container() {
  width: 100%;
  height: 100%;
}

@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-center-space-between() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
