.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    border-bottom: 1px solid #ddd;

    .shortcutDate {
        font-size: 12px;
        color: #989898;
        margin-right: 50px;
        height: 50px;
        line-height: 50px;
        cursor: pointer;
        position: relative;
    }

    .shortcutDateActive {
        color: #01a699;
        border-bottom: 2px solid #01a699;
    }

    .shortcutDateDisabled {
        // cursor: not-allowed !important;
        cursor: auto !important;
        color: rgba($color: #989898, $alpha: 0.5);
    }

    .calendarWrapper {
        position: absolute;
        bottom: 110%;
        left: 650px;
        box-sizing: border-box;
    }
}