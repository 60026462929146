@import '~global/styles';

.actions {
  display: flex;
  position: absolute;
  width: 100%;
  top: -100px;
  align-items: center;
  justify-content: center;

  button {
    transform: rotate(45deg);
    > div > svg {
      path:nth-of-type(1) {
        fill: $white;
      }
    }
  }
  .minus {
    transform: rotate(90deg);
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  .plus {
    transform: rotate(135deg);
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
}
