@import '~global/styles';

$width: 26px;

.menuButton {
    position: relative;
    width: $width;
    height: $width;
    border-radius: $width;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    
    svg {
        cursor: pointer;
    }
}

.menuButtonHover {
  background-color: #D8D8D8;

  svg {
    fill: #838383;
  }
}

.rightMenu {
  width: 220px;
  background: $white;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.37), 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 19px 0 10px;
  font-size: 14px;

  .rightMenuTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 8px 19px;

    svg {
        fill: rgba(0, 0, 0, 0.37);
    }
  }

  .menuItem {
    padding: 0 12px 0 19px;
    line-height: 28px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .menuItemName {
      font-size: 12px;
      color: $text-dark;
      white-space: nowrap;
      min-width: 170px;
    }

    img {
      width: 24px;
      display: none;
    }

    &:hover {
      background-color: $hover-background;

      .menuItemName {
        color: $green;
      }

      img {
        display: block;
      }
    }
  }
}