@import '~global/styles';

.checkboxContainer{
    position: absolute;
    top: -100px;
    right: 0;
    z-index: 10000;
    left: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &>div{display: inline-block;}
    &>div>div{
      width: 25px !important;
      min-width: 25px  !important;
      height: 25px  !important;
      background-color: rgba(255, 255, 255,0.6)  !important;
      margin: 0 auto !important;
    }
    svg{
      fill: $red !important;
      width: 24px !important;
    }
   
  }

.showDeleteImageBtn {
  position: absolute;
}