.grid {
  border: 1px solid #D8D8D8;
  width: 38px;
  height: 38px;
  margin: 0 -1px -1px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: default;

  &.active{
    cursor: pointer;
  }
  @mixin fill {
    position: absolute;
    z-index: 1;
    width: 26px;
    height: 26px;
  }

  .fillIn {
    @include fill;
    background-color: #74B6B6;

  }

  .fillOut {
    @include fill;
    background-color: #EC7765;
  }

  .fillInOut {
    @include fill;
    border-right: 26px solid #EC7765;
    border-top: 26px solid #74B6B6;
  }

  .fillIn + .day, 
  .fillOut + .day,
  .fillInOut + .day {
    color: #fff;
  }

  .day {
    font-size: 14px;
    position: relative;
    z-index: 1;
  }
}