.closable-card {
  position: relative;
  max-width: 170px;
  max-height: 260px;
  height: inherit;
  overflow: hidden;


  .close-card {
  }
}

.card-large {
  max-height: 360px;
  width: 220px;
}
