
.cardTitle {
    font-size: 16px;
    color: #666;
    padding-left: 18px;
}

.cardWrapper {
    height: 157px;
    padding-bottom: 14px;

    .cardItem {
        height: 100%;
        border-right: 1px solid #D8D8D8;
        flex: 1;
        padding-inline: 18px;
        margin-top: 8px;
    
        .value {
            font-size: 32px;
            color: #666;
        }
    
        .percent {
            font-size: 14px;
            font-weight: bold;
            color: #01A699;
        }
    }

    .cardItem:last-child {
        border-right: none;
    }
}

.cardClickable:hover {
    border: 1px solid #74B6B6;
    box-shadow: 0 2px 15px 0 rgba(#74B6B6, 0.3);
}

.vendorName {
    padding-top: 11px;
    font-size: 14px;
    font-weight: bold;
    color: #666;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.value {
    font-size: 32px;
    font-weight: 200;
    color: #666;
    margin-bottom: 4px;
}

.changePercent {
    font-size: 14px;
    font-weight: bold;
    color: #01A699;
}

.changeType {
    font-size: 12px;
    font-weight: lighter;
    transform: scale(0.83);
    transform-origin: center left;
}

/* ******************************* weekly newness ************************************** */
.weeklyBarChartWrapper {
    overflow-x: auto; 
    overflow-y: hidden; 
    position: relative;
}

.weeklyBarChartWrapper::-webkit-scrollbar-thumb { 
    width: 154px;
}

/* ******************************* product card ************************************** */
.productCardContainer {
    padding-top: 18px;
    padding-left: 30px;
    padding-right: 30px;
    overflow: hidden;
}

.productCardTitle {
    font-size: 14px;
    color: #666;
    padding-bottom: 16px;
}

.productCardContent {
    overflow: auto !important;
    max-width: 100%;
    white-space: nowrap;
}

.productCardContent::-webkit-scrollbar-thumb { 
    width: 150px;
}

.productItem {
    display: inline-block;
    margin-right: 12px;
    padding-bottom: 30px;

    .imageWrapper {
        height: 163px;
        width: 110px;
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: #ffffff;
        cursor: pointer;
        position: relative;
    
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }
    }

    .brand {
        font-size: 12px;
        transform: scale(0.92);
        transform-origin: left center;
        color: #666;
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .category {
        font-size: 12px;
        color: #666;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .productItemInner {
        background-color: #fff;
        width: 110px;
    }

    .productItemInner:hover {
        transform: scale(1.08);
        transform-origin: 50% 0%;
        transition: transform .1s ease-in-out;
    }
    .productFirstItemInner:hover {
        transform: scale(1.08);
        transform-origin: 0% 0%;
        transition: transform .1s ease-in-out;
    }
}

.descTooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
}