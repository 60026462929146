$padding-inline: 8px;

.wrapper {
    position: sticky;
    top: -30px;
    z-index: 3;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    max-width: 100%;
    width: fit-content;
    overflow: auto;
}

.title {
    font-size: 14px;
    font-weight: bold;
    color: #484848;
    display: flex;
    
    td {
        padding-left: $padding-inline;
        height: 38px;
        line-height: 38px;
        box-sizing: border-box;

        &:first-child {
            position: sticky;
            left: 0px;
            z-index: 2;
            background-color: #fff;
        }

        .titleRowWeekday {
            display: flex;
            justify-content: center;
        }
    }
}

.fixedColumn {
    position: sticky;
    z-index: 1;
    background-color: #fff;
}

.greenBoldBorderRight {
    border-right: 1px solid #01A699;
}

.cellPx {
    padding-inline: 8px;
}

.draggable {
    cursor: grab;
}
