.container {
    min-height: calc(100vh - 65px);
    position: relative;
    padding: 20px 0;
    background-color: #f8f7f5;
}

.form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    margin-left: 25px;
}