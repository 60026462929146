@import '~global/styles';

.container {
    position: fixed;
    width: 85px;
    left: 0;
    top: 0;
    bottom: 0;
    background: white;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.19);
    z-index: 9999990;
    display: flex;
    flex-direction: column;
}

.items {
    padding-top: 26px;

    &>div {
        text-align: center;
        padding: 6px 0px 0px;
        margin-bottom: 20px;

        &:first-child {
            margin-bottom: 0px;
        }

        line-height: 34px;
        min-height: 60px;
        width: 85px;
        box-sizing: content-box;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;

        &>div:first-child {
            position: relative;
        }

        .icon {
            display: block;
            margin: 0 auto;
        }

        &:hover {
            background-color: #F1F1F1;
            content: ' ';
            width: 100%;
        }

        .label {
            font-size: 10px;
            line-height: 1;
            text-transform: uppercase;
            color: #666666;
            width: 85px;
            display: block;
            position: relative;
            top: -12px;
            transform: scale(0.8);
            user-select: none;

            &[class*="Assortment"] {
                top: -6px;
                // transform: translateX(calc(-50% + 12px)) scale(.9);
            }

            &[class*="Resource"] {
                bottom: -6px;
            }
        }

        svg {
            width: 100%;
            height: 100%;
            fill: #404040;
            display: block;
            transform: scale(0.666666667);

            &[class*="assortment"] {
                transform: scale(1);
            }
        }
    }
}

.blankSpace {
    flex: 1
}

.searchContainer {
    display: flex;
    flex-direction: column;

    &>:first-child {
        height: 30px;

        input {
            height: 26px;
            font-size: 11px;
            ;
        }
    }

    &>:last-child {
        margin: 4px 0px -0 !important;
        font-size: 11px;
        height: 26px !important;

        &:empty {
            display: none;
        }
    }
}

.dateInput {
    opacity: 0;
    max-width: 100%;
    position: absolute;
    left: 0;
    background: transparent;
    top: 0;
    height: 100%;
    cursor: pointer;
}

.menubarItem {
    position: relative;

    button, svg {
        cursor: pointer;
    }

    &>div>button {
        border: none;
        background: transparent;
    }

    .menubarItemPanel {
        display: none;

    }

    .arrow {
        position: absolute;
        height: 24px;
        width: 24px;
        position: absolute;
        left: -12px;
        transform: rotate(45deg);
        z-index: -1;
        top: 34px;
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.35);
        background: white;
    }

    &.active {
        .menubarItemPanel {
            top: -13px;
            display: block;
            position: absolute;
            width: 280px;

            z-index: 1;
            text-align: left;
            left: 87px;
            border-radius: 9px;
            box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.35);

            .menubarItemPanelContent {
                padding: 28px 0;
                background: white;
                border-radius: 9px;
            }

            .title {
                color: #EC7765;
                font-size: 16px;
                line-height: 1;
                padding-left: 25px;
                padding-bottom: 12px;
                ;
            }

            ul {
                list-style: none;

                li {

                    button,
                    a {
                        padding-left: 25px;
                        font-size: 14px;
                        color: #666;
                        height: 32px;
                        display: block;
                        text-decoration: none;
                        width: 100%;

                        &:hover {
                            background: #F1F1F1;
                            color: #01A699;
                            cursor: pointer;
                        }

                        &.active {
                            color: #01A699;
                        }
                    }

                    button:disabled {
                        cursor: not-allowed;
                        color: #999999;

                        svg {
                            cursor: not-allowed;
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
    }
}

.disabled,
.menu-disabled {
    cursor: not-allowed !important;

    svg {
        cursor: not-allowed;
        opacity: 0.5;
    }

    span {
        color: #aaa !important;
        background-color: transparent !important;
    }

    &:hover {
        background-color: transparent !important;
    }

}

.navs {
    list-style: none;
    padding: 0 26px 0 40px;
    display: flex;
    align-items: center;
    white-space: nowrap;

    & > li {
        display: inline-block;
        color: #666666;
        font-weight: 400;
        font-size: 14px;

        & > span {
            font-weight: 600;
        }

        & > a {
            text-decoration: none;
            color: #666666;
        }
    }

    & > li:first-of-type {
        display: inline-flex;
        flex-direction: row;
        align-items: center;

        .categories {
            max-width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
        }
    }

    .selectedTimePeriodWrapper {
        padding-left: 8px;
        color: #666;
        font-family: "Nunito Sans";
        font-size: 14px;
        display: flex;
        align-items: center;
        font-weight: initial;
    }

    .verticalLine {
        height: 27px; 
        width: 1px; 
        background: rgba(0,0,0,.1);
        margin-right: 8px;
    }

    .dateRangeValue {
        cursor: pointer;
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        font-weight: 600;
        color: #01A699;
    }
}

.filterDisplay {
    position: fixed;
    top: 0px;
    left: 85px;
    background-color: white;
    z-index: 9999990;
    right: 0;
    border-bottom: 1px solid rgba(151, 151, 151, 0.3400);
}

.filtersItem {
    flex: 1;
    flex-wrap: wrap;
    max-width: calc(100% - 100px);
    width: calc(100% - 100px);
}

.filtersItemContainer {
    height: 23px;
    overflow: hidden;
    height: auto;
    display: inline-flex;
    flex-wrap: wrap;
}

.filters {
    padding: 12px 40px 8px;
    list-style: none;
    border-top: 1px solid rgba(151, 151, 151, 0.3400);
    display: flex;
    align-items: flex-start;

    li {
        display: inline-flex;
        align-items: center;
    }

    .title {
        font-size: 11px;
        color: #666666;
        line-height: 12px;
        margin-right: 14px;
        margin-top: 7px;
    }

    .green,
    .tag {
        height: 22px;
        background: #EC7765;
        border-radius: 4px;
        border: 1px solid #EC7765;
        font-size: 11px;
        color: white;
        line-height: 14px;
        padding: 0 5px;
        margin-right: 4px;
        margin-bottom: 4px;
        order: 1;
    }

    .green {
        background: #01A699;
        border: 1px solid #01A699;
        cursor: pointer;
        order: 100;
    }

    .grey {
        background: #999999;
        border: 1px solid #999999;
        order: 100;
    }

    .lineThrough {
        background-color: #fff;
        border: 1px solid #999999;
        text-decoration: line-through;
        color: #333;
        order: 100;
    }

    .masked {
        background-color: #cccccc;
        border: 1px solid #cccccc;
    }

    .regionTag, 
    .currencyTag,
    .sortOrderTag,
    .priceFilterTag {
        order: 1;
    }
}

.navDate {
    position: relative;
    background-color: white;

    &.disabled {
        cursor: not-allowed;

        input:disabled {
            cursor: not-allowed;

        }
    }

    [class*="Calendar "] {
        width: 320px;
        margin: 0 auto;
        box-shadow: none;
    }

    .line {
        width: 1px;
        height: 100%;
        background-color: rgba(151, 151, 151, .5);
        margin-left: 10px;
        margin-right: 10px;
    }

    .title {
        color: #666 !important;
        padding-left: 38px !important;
        padding-bottom: 0px !important;
    }

    .subtitle {
        font-size: 14px;
        color: #666;
        padding-left: 38px;
        margin-bottom: 20px;
    }

    .date {
        border-radius: 18px;
        height: 36px;
        border: 1px solid #CCCCCC;
        display: flex;
        width: 280px;
        margin: 0 auto;
        cursor: auto;

        & > div {
            width: 50%;
            text-align: center;
        }

        [class*="dropdownExpanded"] {
            line-height: initial;
        }
    }

    .dateLabel {
        color: $primary-color;
        font-size: 14px;
    }

    [class*="_select_"] {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: none !important;
        border-left: 1px solid #CCCCCC !important;
    }
}

.collapse {
    border: none;
    background: transparent;
    height: 24px;
    width: 24px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;

    svg {
        transform: rotate(90deg);
        width: 12px;
        height: 12px;
        margin-left: 5px;
        position: relative;
        top: 3px;
    }

    &.open {
        svg {
            transform: rotate(-90deg);
            top: -3px;
        }
    }
}

.num {
    font-size: 12px;
    color: #474747;
    cursor: pointer;
    user-select: none;
}

.subTitle {
    color: #666;
    font-family: "Nunito Sans";
    font-size: 14px;
    display: flex;
    padding-left: 5px;
    display: flex;
    align-items: center;
    font-weight: initial;
}

.account {
    display: inline-flex;
    align-items: center;
    position: absolute;
    right: 0;
    z-index: 8;
    background: white;

}

.tagWithTips {
    white-space: nowrap;
    color: inherit;
    text-decoration: none;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flow-root;
    cursor: inherit;
}

.menuItem {
    display: inline-flex !important;
    align-items: center !important;
    gap: 10px !important;

    .pinWrapper {
        display: none;
        width: 24px;
        height: 24px;
    
        & > svg {
            transform: unset !important;
            height: unset !important;
            width: unset !important;
        }

        &:hover {
            border-radius: 4px;
            background-color: rgb(230, 230, 230);
        }
    }

    &:hover {
        .pinWrapper {
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
    }
}
