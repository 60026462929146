.tooltip {
    max-width: 300px;
    padding: 20px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.4em;
    word-wrap: break-word;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.3) !important;
    font-family: "Nunito Sans", sans-serif;
    border-radius: 4px;
    z-index: 9999999;
    position: fixed;

    &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        border-width: 10px;
        border-style: solid;
        transition: display .3s;
        border-color: #fff transparent transparent transparent;
        z-index: 9999999;
    }
}
