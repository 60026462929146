.dialog {
    inset: unset !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    border: none !important;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05) !important;
    padding: 40px 100px !important;
    border-radius: 10px !important;
}

.form {
    width: 250px;
}

.input {
    width: 250px;
    height: 36px;
    line-height: 36px;
    border-radius: 36px;
    background-color: #F8F8F8;
    padding-inline: 15px;
    padding-block: 0px;
    font-size: 12px;

    &:focus, &:hover, &:focus-visible, &:focus-within {
        border: 1px solid #d9d9d9;
        box-shadow: unset;
    }

    &::placeholder {
        font-size: 12px;
        color: #888;
    }
}