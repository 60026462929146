@import '~global/styles';

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
}

.sizeFilter {
  width: 400px;
  background: #FBFBFB;
  box-shadow: 0px 2px 28px 4px rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  font-size: 14px;
  position: fixed;
  left: calc(50% - 200px);
  top: 200px;
  z-index: 99999;
  padding-top: 30px;
  padding-bottom: 30px;

  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 340px;

    .title {
      width: 100%;
      font-size: 22px;
      color: #ec7765;
      text-align: center;
    }

    .text {
      font-size: 11px;
      line-height: 1.5;
      color: #4A4A4A;
      margin-top: 20px;
      margin-bottom: 26px;
      text-align: center;
      width: 100%;
    }

    .applyBtn {
      width: 125px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}
