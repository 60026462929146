$firstColumnPadLeft: 6px;

.title {
    padding-top:34px;
    font-family: 'Nunito Sans';
    font-size: 16px;
    color: #666666;
    letter-spacing: -0.12px;
}

.content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    position: relative;
    top: 6px;
    scrollbar-width: none;
}

.firstColumnPl {
    padding-left: $firstColumnPadLeft;
}
