@import '~global/styles';

.descriptionWrap {
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  height: 100%;
  line-height: 1;
  padding-top:2.5em;
}

.totalProducts,
.avaragePrice {
  margin-left: 5px;
}

.showAllButtonWrap {
  margin-top: 50px;
  button {
    justify-content: left;
    padding: 0 10px;
    display: flex;
    border: none;
    background: transparent;
    cursor: pointer;
    height: 36px;
    text-align: center;
    align-items: center;
    width: 100px;
    svg {
      fill: $green;
    }
    span{
      white-space: nowrap;
    }
  }
}
