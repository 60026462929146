.switch {
  width: 36px;
  height: 19px;
  border-radius: 19px;
  background-color: #fff;
  border: solid 1px #cecece;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  .handle {
    width: 17px;
    height: 17px;
    border-radius: 17px;
    background-color: #01a699;
    position: relative;
  }
}

.label{
  font-size: 10px;
  color: #666;
  text-align: left !important;
  margin-bottom: 4px;
  height: 16px;
  line-height: 16px !important;
  text-transform: uppercase;
  font-family: Nunito sans;
  font-weight: normal;
}

.switchWrapper {
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.activeItem {
  color: #01a699;
}

.item {
  color: #666;
}