
@use "sass:math";

$barColor: #1D756E;
$circleWidth: 25px;
$circleDiameter: 140px;
$outerCircleDiameter: 130px;
$outerCircleDistance: math.div($circleDiameter - $outerCircleDiameter, 2);
$innerCircleDiameter: 100px;
$innerCircleDistance: math.div($circleDiameter - $innerCircleDiameter, 2);


.chartWrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    transform: rotateZ(200deg);
}

.left {
    position: relative;
    overflow: hidden;
    z-index: 2;
    left: 0.5px;

    .leftCircle {
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        border: $circleWidth solid transparent;
        border-top: $circleWidth solid $barColor;
        border-left: $circleWidth solid $barColor;
        transform: rotate(45deg);
    }
}

.right {
    position: relative;
    overflow: hidden;
    z-index: 2;

    .rightCircle {
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
        border: $circleWidth solid transparent;
        border-top: $circleWidth solid $barColor;
        border-right: $circleWidth solid $barColor;
        transform: rotate(45deg);
    }
}

.innerCircle {
    position: absolute;
    left: $innerCircleDistance;
    top: $innerCircleDistance;
    width: $innerCircleDiameter;
    height: $innerCircleDiameter;
    border-radius: 50%;
    background-color: #ffffff;
    z-index: 2;
    transform: rotateZ(-200deg);
}

.outerCircle {
    position: absolute;
    left: $outerCircleDistance;
    top: $outerCircleDistance;
    width: $outerCircleDiameter;
    height: $outerCircleDiameter;
    border-radius: 50%;
    background-color: #D7D7D7;
    z-index: 1;
}