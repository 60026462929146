.week {
    display: flex;

    .weekTitle {
        width: 38px;
        text-align: center;
        font-size: 10px;
        color: #999999;
        margin-bottom: 5px;
    }
}

.calendarRow {
    display: flex;

    .calendarRowWeek {
        width: 38px;
        font-size: 10px;
        color: #999999;
        text-align: right;
        padding-right: 5px;
        padding-top: 5px;
    }

    .calendarRowCell {
        border: 1px solid #D8D8D8;
        width: 38px;
        height: 38px;
        margin: 0 -1px -1px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
    }
}
