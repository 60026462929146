@import '~pages/Discounts/components/DiscountsTable/styles.module';

.vendorsWrapper {
    position: sticky;
    z-index: 12;
    background-color: #fff;
}

.vendors {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-family: "Nunito Sans";
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    line-height: 1.5rem;
    font-size: 0.875rem;
    letter-spacing: 0.01071em;
    background-color: #fff;

    .discount {
        min-width: $firstColumnWidth;
        padding-top: $headerFirstRowHeight + $headerPt;
        line-height: 40px;
        position: sticky;
        background-color: #fff;
        z-index: 10;
        left: 0;
        border-bottom: 1px solid $normalBorderColor;
    }

    .vendor {
        height: 112px - $pb;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: grab;
        border-bottom: 1px solid $normalBorderColor;

        .firstColumn {
            padding-left: $pl;
            padding-top: $headerPt;
            width: $currentValueWidth + $pl;
    
            .vendorLogoWrapper {
                display: flex;
                width: $currentValueWidth;
                height: $headerFirstRowHeight;
                flex-direction: row;
                align-items: center;
                cursor: grab;
                min-height: 50px;
                
                svg {
                    max-width: $currentValueWidth - 10px;
                    height: $headerFirstRowHeight;
                    transform: scale(1) !important;
                }
                img{
                    max-width: calc(100% - 10px) !important;
                    max-height: $headerFirstRowHeight !important; 
                    width: auto !important;
                }
            }

            .vendorPlatform {
                width: $currentValueWidth;
                height: $headerSecondRowHeight;
                font-size: 12px;
                color: #666;
                line-height: $headerSecondRowHeight;
                white-space: nowrap;
                text-decoration: none;
                overflow: hidden;
                text-overflow: ellipsis;
                display: flow-root;
                cursor: inherit;
                height: 100%;
                cursor: grab;
            }
    
            .vendorName {
                font-family: Nunito Sans;
                text-align: left;
                color: rgba(0,0,0,.87);
                font-weight: 500;
                font-size: .875rem;
                letter-spacing: .01071em;
                height: $headerFirstRowHeight + $headerSecondRowHeight;
                line-height: $headerFirstRowHeight + $headerSecondRowHeight;
                white-space: nowrap;
                text-decoration: none;
                overflow: hidden;
                text-overflow: ellipsis;
                display: flow-root;
                cursor: inherit;
                cursor: grab;
            }
        }

        .secondColumn {
            width: $comparisonValueWidth + $changeWidth - $pl;
            padding-top: $headerPt;
            padding-bottom: $pb;
        
            .firstRow {
                height: $headerFirstRowHeight;
                line-height: $headerFirstRowHeight;
                font-size: 14px;
                color: #666;
            }
        
            .secondRow {
                height: $headerSecondRowHeight;
                line-height: $headerSecondRowHeight;
                font-size: 11px;
                color: #666;
                padding-left: $comparisonValueWidth;
            }
        }
    }
}

.shadow {
    position: sticky;
    top: -15px;
    height: 90px;
    margin-top: -109px;
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    z-index: 11;
}
