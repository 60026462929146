@import '~global/styles';

.panel {
    background-color: #fff;
    border-radius: 9px;
    user-select: none;
    display: flex;
    flex-direction: column;
    width: 300px;
    z-index: 10;
    padding-bottom: 20px;

    .panelHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;

        .panelHeaderYear {
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
            padding: 4px 8px;

            &:hover {
                background-color: #f8f8f8;
            }
        }
    }

    .panelBody {
        box-sizing: border-box;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;

        .panelItem {
            width: 33.3%;
            height: 30px;
            line-height: 30px;
            margin-top: 18px;
            text-align: center;
            cursor: pointer;

            &:not(.active):not(.disabled):hover {
                background-color: rgba(1,166,153,.5);
                color: #fff;
                border-radius: 4px;
            }
        }

        .active {
            background-color: $green;
            color: #fff;
            border-radius: 4px;
        }

        .disabled {
            opacity: 0.7;
            cursor: not-allowed;
        }
    }
}
