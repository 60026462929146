$padding-inline: 8px;

.wrapper {
    position: sticky;
    top: -30px;
    z-index: 3;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    max-width: 100%;
    width: fit-content;
    overflow: auto;
}

.title {
    font-size: 14px;
    font-weight: bold;
    color: #484848;
    
    td {
        padding-left: $padding-inline;
        height: 28px;
        line-height: 28px;

        &:first-child {
            position: sticky;
            left: 0px;
            z-index: 2;
            background-color: #fff;
        }
    }
}

.subtitle {
    font-size: 12px;
    color: #6C6C6C;

    td {
        padding-left: $padding-inline;
        height: 24px;
        line-height: 24px;
        white-space: nowrap;

        &:first-child {
            position: sticky;
            left: 0px;
            z-index: 2;
            background-color: #fff;
        }
    }
}

.fixedColumn {
    position: sticky;
    z-index: 1;
    background-color: #fff;
}

.greenBoldBorderRight {
    border-right: 1px solid #01A699;
}

.tableTitle {
    font-size: 16px !important;
    font-weight: normal !important;
    color: #666 !important;
    padding-left: 29px !important;
}
