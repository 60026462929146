@import '~global/styles';

$valueWidth: 50px;
$lineHeight: 24px;

.list {
    width: 390px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    .title {
        font-size: 12px;
        font-weight: 600;
        color: #484848;
    }

    .subtitle {
        margin-top: 10px;
        padding-bottom: 8px;

        .subtitleItem {
            font-size: 12px;
            transform: scale(calc(11 / 12));
            font-weight: 600;
            font-family: "Nunito Sans", sans-serif;
            color: #484848;
        }
    }
}

.content {
    position: relative;

    .listBody {
        position: relative;
        background-color: transparent;
    }

    .materialPageListBody {
        max-height: 360px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    button.disabled {
        cursor: auto;

        &:hover,
        &:active,
        &:focus,
        &:focus-within {
            background-color: transparent !important;
        }
    }

    button:last-child {
        &>div {
            border-bottom: none;
        }
    }

    button {
        cursor: pointer;
        list-style: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: $lineHeight;
        line-height: $lineHeight;
    }

    .keyFont {
        text-align: center;
        color: #666666;
        font-family: "Nunito Sans";
        font-size: 12px;
        font-weight: 400;
    }

    .dotWrap {
        display: flex;
        align-items: center;

        .dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 0.35px solid #A0A0A0;
        }
    }

    .colorFont {
        color: #666666;
        font-family: "Nunito Sans";
        font-size: 12px;
        font-weight: 600;
        text-align: left;
        width: $valueWidth;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .valueFont {
        color: #666666;
        font-family: "Nunito Sans";
        font-size: 10px;
        font-weight: 400;
        padding-left: 6px;
        text-align: left;
    }

    img {
        visibility: hidden;
        width: 24px;
        margin-right: 2px;
    }

    .deepBorderRight {
        border-right: 1px solid $primary-color;
    }

    .normalBorderBottom {
        border-bottom: 1px solid rgba($color: $primary-color, $alpha: 0.4);
    }

    .normalBorderTop {
        border-top: 1px solid rgba($color: $primary-color, $alpha: 0.4);
    }

    button:not(.disabled):hover {
        background-color: #F1F1F1;
        border-radius: 4px;

        img {
            visibility: visible;
        }
    }
}

.noData {
    text-align: center;
    padding-top: 180px;
}

.arrowWrapper {
    svg {
        fill: $primary-color;
    }
}

.lockWrapper {
    position: relative;

    .lockTr {
        height: 24px;
        border-right: 1px solid #01A699;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:last-child {
            border-bottom: none;

            .colorFont,
            .restSpace {
                border-bottom: none;
            }
        }
        
        .colorFont {
            height: 24px;
            line-height: 24px;
        }

        .restSpace {
            flex: 1;
            height: 24px;
        }
    }

    .verticalDashedLine {
        position: absolute;
        height: 100%;
        width: 1px;
        left: calc(50% + 70px);
        top: 0;
        background-image: linear-gradient(to bottom, rgb(216, 216, 216) 55%, #fff 0%); /* 35%设置虚线点y轴上的长度 */
        background-position: right; /* top配置上边框位置的虚线 */
        background-size: 1px 10px; /* 第一个参数设置虚线点的间距；第二个参数设置虚线点y轴上的长度 */
        background-repeat: repeat-y;
        transform: translateX(-50%);
        z-index: 0;
    }

    .lockIconWrapper {
        position: absolute;
        left: calc(50% + 70px);
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
