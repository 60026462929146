.tips {
    &:before{
        content: " ";
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid #fff;
        border-right: 10px solid transparent;
        position: absolute;
        left: 10px;
        top: -19px;
        border-left: 10px solid transparent;
    }
}

.backBtn {
    color: #01a699;
    font-size: small;
    cursor: pointer;
    margin-left: 40px;
    margin-bottom: 0px;
    margin-right: 15px;
    display: inline-flex;
    align-items: center;
}
