.info {
  position: relative;
  display: inline-block;

  .popover {
    display: block;
    position: absolute;
    top: 33px;
    left: -171px;
    padding: 0px;
    font-family: inherit;
    font-size: 14px;
    line-height: 19px;
    text-align: left;
    color: #000;
    background-color: #fff;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.0998416);
    border-radius: 4px;
    z-index: 10;
    margin-left: 14px;
  }

  .infoIcon {
    position: relative;
    cursor: default;
    height: 100%;
    display: flex;
    align-items: center;
  }
}