@import '~global/styles';

.dateRangeInput {
  // text-align: center;
  border: 1px solid transparent;
  color: $green;
  cursor: pointer;
  border-radius: 40px;
  outline: none;
  height: 32px;
  font-size: 11px;
  background: transparent;
  padding-left: 34px;
  width:100%;
}

.dateRangeInputEmpty {
  @extend .dateRangeInput;
  color: #666666;
  border: 1px solid transparent;
  width: 100%;
  height: 32px;
  padding-left: 34px;
}

.resetButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 15px !important;

  > button {
    border: none;
    background: $green !important;
    color: $white;
    padding: 10px 20px;
    border-radius: 10px;
  }
}

.labelContainer {
  margin-bottom: 8px;
}

.container {
  z-index: 1;
  margin-top: 0px;
  >div{
    z-index: 1;
    >div{
      top:-300px;

    }
  }
}
