
$firstColumnWidth: 100px;

.left {
    justify-content: start;
}

.center {
    justify-content: center;
}

.right {
    justify-content: end;
}

.table {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    .thead {
        display: flex;
        flex-direction: row;
        position: sticky;
        top: 65px;
        background: #fff;
        z-index: 100;

        div {
            position: relative;
            z-index: 1;
            background-color: #fff;
        }

        & > div:first-child {
            position: sticky;
            left: 0px;
            z-index: 9;
        }

        & > div:nth-child(2) {
            position: sticky;
            left: $firstColumnWidth;
            z-index: 9;
        }

        & > div:last-child {
            position: sticky;
            right: 0px;
            z-index: 9;
        }
    }

    .tbody {
        .tr {
            display: flex;
            position: relative;

            .td {
                font-family: 'Nunito Sans';
                font-size: 14px;
                color: #484848;
                flex-shrink: 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            .tdBorderTop {
                border-top: 2px solid rgb(1, 166, 153);
            }

            .tdBorderBottom {
                border-bottom: 2px solid rgb(1, 166, 153);
            }

            .tdBottomIcon {
                position: absolute;
                display: flex;
                justify-content: center;
                width: 100%;
                z-index: 10;
                bottom: 0;
                opacity: 0.7;

                svg {
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    left: 47.5%;
                    top: -13px;
                    background: #fff;
                    border-radius: 30px;
                    box-shadow: 0 2px 9px 0 rgba(#000000, 0.3);
                }
            }
        }

        .trLevel0 {
            & > .td,
            & > div:last-child {
                background-color: rgb(216, 235, 235) !important;
            }
        }

        .trLevel1 {
            & > .td,
            & > div:last-child {
                background-color: #ffffff !important;
            }
        }

        .trLevel2 {
            & > .td,
            & > div:last-child {
                background-color: rgb(240, 240, 240) !important;
            }
        }

        .trLevel0, .trLevel1, .trLevel2 {
            & > .td {
                z-index: 1;
            }

            & > .td:first-child {
                position: sticky;
                left: 0px;
                z-index: 9;
            }

            & > .td:nth-child(2) {
                position: sticky;
                left: $firstColumnWidth;
                z-index: 9;
            }

            & > div:last-child {
                position: sticky;
                right: 0px;
                z-index: 9;
            }
        }
    }
}
