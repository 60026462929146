@import '~global/styles';

.productImage {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 400px;
    position: relative;
    z-index: 2;
  
    .bigImage {
      width: 400px;
      height: 600px;
      max-width: 400px;
      max-height: 600px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .smallImageList {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }
  
    .smallImageItem {
      width: 121px;
      height: 98px;
      margin-right: 18px;
      margin-top: 29px;
      cursor: pointer;
      position: relative;
  
      &:nth-child(3n) {
        margin-right: 0px;
      }

      img {
        width: 100% !important;
        height: 100% !important;
      }
    }
  
    .selectedSmallImageItem {
      border: 0.1px solid $orange;
    }
} 

.imageWrapper {
  position: relative;

  div[class*="checkbox-container"]{
    transform: scale(1.8);
    background-color: #ffffffd4;
    padding: 10px 10px;
  }
  div[class*="checkboxContainer"]{
    top: -40px;
    text-align: right;
    justify-content: flex-end;
    z-index: 0;
    left: auto;
    right: -90px;
    div[class*="checkbox-container"]{
      padding: 0;
    }
  }
  div[class*="showDeleteImageBtn"]{
    top: -40px;
    text-align: right;
    justify-content: flex-end;
    z-index: 0;
    left: auto;
    right: -220px !important;
  }
}