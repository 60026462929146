@import '~global/styles';

.container {
  background-color: #fff;
  box-sizing: content-box;
  min-height: 1200px;
  min-width: 1300px;
  margin: 0;
}

