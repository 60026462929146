@import '../styles';

.initialLayout {
  width: 100%;
}

.flex {
  display: flex;
  width: 100%;
}
