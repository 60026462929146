@import '~global/styles';

.tablecell1 {
    padding: 2px 5px;
    height: 43px;
    border-bottom: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    line-height: 1.2;
    white-space: pre-wrap;
    display: flex;
    flex: 1;
    align-items: center;
    color: #484848;
    word-break: normal;

    & > svg {
      position: relative;
      width: 16px;
      height: 16px;
      margin-right: 3px;
    }

    .loadingIcon {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
}

.cellBorder {
  border-bottom: 1px solid #d8d8d8;
}

.tableCellTwo {
  display: flex;
  justify-content: space-between;
  color: $text-dark;
  // background-color: rgba(116, 182, 182, 0.15);
  background-color: rgb(235, 243, 243);
  align-items: baseline;
  height: 34px;
  padding: 0px 5px;
  line-height: 34px;
  margin: 4px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  span:last-child {
    color: #74b6b6;
    font-size: 10px;
  }
}

.shadowBgCell {
  background-color: rgb(244, 246, 246);
}

.whiteBgCell {
  background-color: #fff;
}