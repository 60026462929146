@import '~global/styles';

.backButton {
    color: $green;
    font-size: small;
    cursor: pointer;
    margin-left: 40px;
    margin-bottom: 0px;
    margin-right: 15px;
    display: inline-flex;
    align-items: center;
}

.vendorPadLeftHeader {
    width: 260px;
    height: 68px;
    background-color: $white;
    position: sticky;
    left: 0;
    flex-shrink: 0;
    border-right: 1px solid #edefee;
    z-index: 20;
}

.vendorHeaderFirst {
    position: sticky;
    left: 260px;
    background-color: $body-background;
    z-index: 2 !important;
    box-shadow: 3px 0px 10px 0 rgba(0,0,0,0.04);
    font-weight: 700;
}

.vendorHeaderOthers {
    position: relative;
    z-index: 1;
}

.vendorHeader {
    flex-shrink: 0;
    height: 68px;
    width: 260px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-right: 1px solid #edefee;
    font-family: Nunito Sans,sans-serif;
    font-size: 16px;
    color: #666;

    .vendorHeaderZoom > svg {
        cursor: pointer;
    }

    .vendorHeaderName {
        flex: 1;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .vendorHeaderClose {
        position: relative;
        bottom: 10px;
    }
}

.searchHeader {
    position: sticky;
    top: 0px;
    background: #fff;
    z-index: 111;
}
