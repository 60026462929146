@import '~global/styles';

.contentContainer {
  width: 100%;
  margin:0 auto;

  .myCollections {
    padding: 0 20px;
    margin: 0 auto;

    hr {
      margin: 10px 0 0 0;
    }
  }

  .charWrap {
    padding: 20px 60px 20px 60px;
    width: 100%;
    height: 500px;
  }
}

.noPadding {
  padding: 0;
}


