
.thead {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: #666666;
    border-bottom: 0.5px solid #000;
    margin-bottom: 5px;
    height: 24px;
    line-height: 24px;
    padding: 0 4px;
}

.tbody {
    .tr {
        display: flex;
        flex-direction: row;
        font-size: 11px;
        color: #424242;
        height: 24px;
        line-height: 24px;
        padding: 0 4px;
    }

    .trActive {
        background-color: rgba($color: #31B4A7, $alpha: 0.1);
        border-radius: 3px;
    }
}