.cardItem {
    width: calc((100% - 40px) / 3);
    margin-left: 10px;
    margin-right: 10px;
}

.cardItem:nth-child(3n - 2){
    margin-left: 0;
}

.cardItem:nth-child(3n) {
    margin-right: 0;
}