.container {
    background-color: #fff;
    padding:20px 20px 20px;
    box-sizing: content-box;
    min-height: 50px;

    .form {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 24px 0;
    }

    .tableTitle {
        margin-top: 3px;
        margin-bottom: 13px;
        font-family: 'Nunito Sans';
        font-size: 16px;
        color: #666666;
        letter-spacing: -0.12px;
    }

    .thead {
        border-top: 1px solid rgba($color: #000000, $alpha: 0.05);
        height: 40px;
        line-height: 40px;
        color: #484848;
        font-size: 14px;
        font-weight: 700;
    }

    .tdOptionsWrapper {
        height: 40px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 100%;
            top: 0;
            height: 42px;
            width: 10px;
            background-color: #fff;
        }

        .optionsInner {
            height: 33px;
            line-height: 33px;
            font-size: 14px;
            color: #666;
            font-family: Nunito sans;
            background-color: rgba($color: #74B6B6, $alpha: 0.25);;
            padding: 0 12px;
            user-select: none;
        }
    }

    .tdCategory {
        display: flex;
        width: 100%;
        align-items: center;

        svg {
            width: 22px;
            height: 25px;
        }
        span {
            display: inline-block;
            margin-left: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .borderRight {
        border-right: 1px solid rgba(#000000, 0.05);
    }

    .borderBottom {
        border-bottom: 1px solid rgba(#000000, 0.05);
    }

    .row {
        height: 40px;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.05);
    }

    .row:last-of-type {
        border-bottom: none;
    }
}

.thVendor {
    line-height: initial;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 12px;
    overflow: hidden;

    .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .platform {
        font-size: 12px;
        transform: scale(calc(0.916667));
        transform-origin: 0%;
        color: rgb(102, 102, 102);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }
}

.theadWrapper {
    position: sticky;
    top: -30px;
    z-index: 99;
    background-color: #fff;
}

.tbodyTable {
    overflow-x: clip !important;
}

.clothesIconWrapper svg {
    width: 16px !important;
    height: 16px !important;
}
