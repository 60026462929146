@import '~global/styles';

.sticky-header {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  background: $white;
}

.right-filter-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: -13px;
  // margin-right: 70px;
}

.display-none {
  display: none;
}

.lookbookHeaderContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: $white;
  height: 90px;

  .lookbookHeader {
    height: 90px;
    background-color: $white;
    padding: 15px;
    display: flex;
    flex-direction: column;

    .AllProductsText {
      font-size: 22px;
    }

    .backLookbookBtn {
      margin-top: 10px;
      cursor: pointer;

      span {
        color: $orange;

        svg {
          margin-right: 5px;
        }
      }
    }

  }

  .analysisBtn {
    position: absolute;
    right: 60px;
    top: 25px;
    display: flex;

    .selectLookbook {
      margin-right: 20px;
    }
  }
}

.loadingContainer {
  width: 100%;
  background: $white;
  z-index: 6;
  padding: 20%;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: inherit;
  }
}