.nornaDoughnut {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .doughnutWrapper {
        position: relative;
        z-index: 2;
        width: 92%;
        height: 92%;
        border-radius: 50%;
    }

    .content {
        position: absolute;
        top: 0;
        left: 0;
        font-family: 'Nunito sans'; 
        text-align: center;
        color: #01A699;
        font-size: 21px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .smallCircle {
        position: absolute;
        width: 56%;
        height: 56%;
        border-radius: 100%;
        background-color: #fff;
    }

    .bigCircle {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: #e3e5e8;
    }
}
