.skeleton-loading {
  width: 100%;
  height: 100%;
  background-color: rgb(238, 238, 238);
  background-image: linear-gradient(90deg, rgb(238, 238, 238), rgb(245, 245, 245), rgb(238, 238, 238));
  background-size: 200px 100%;
  line-height: 1;
  background-repeat: no-repeat;
  border-radius: 4px;
  animation: shine-lines 1s infinite ease-out;
}

@keyframes shine-lines {
  0% {
    background-position: -10%
  }

  40%, 100% {
    background-position: 150%
  }
}
