@import '~global/styles';

.search-bar2 {
  height: 36px !important;
  border-radius: 20px !important;

  >input {
    height: 34px !important;
    margin-top: 0px !important;
  }
}

.search-bar {
  position: relative;
  margin-right: 15px;
  width: 289px;
  border: 1px solid #979797;
  color: rgba(0, 0, 0, 0.38);
  border-radius: 16px;
  height: 34px;

  >input {
    font-size: 16px;
    margin-left: 15px;
    padding: 0;
    height: 30px;
    margin-top: 2px;
    width: calc(100% - 50px);
    color: $text-light;
    text-overflow: ellipsis;

    ::placeholder {
      color: $title-grey;
    }
  }

  .search-icon {
    position: absolute;
    top: 4px;
    right: 14px;
    opacity: 0.5;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  .search-results {
    position: absolute;
    width: 100%;
    top: 50px;
    background: $white;
    z-index: 5;
    border-radius: 3px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
    max-height: 300px;
    font-size: $font-size-small;
    color: $title-grey;
    line-height: 28px;
    min-height: 50px;
    >div {
      cursor: pointer;
      padding: 10px;
      border-radius: 3px;

      &:not(.error):hover {
        background-color: $background-gray;
        color: $title-grey;
      }
    }
    .search-loading{
      div{
        top:0;
      }
    }

    .searchValue {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.close-results {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 0;
}

.error {
  position: relative;

  .message {
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    justify-content: center;
    z-index: 30;
  }
}
