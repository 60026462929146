@import '~global/styles';
.modalBody {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  // justify-content: center;
  // align-items: center;
  padding-bottom: 10px;
  margin-top: 10px;
  overflow: hidden;
  position: relative;

  .imageWrap {
    height: 108px;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
   
  }

  .modalContent {
    background-color: #FAFAF9;
    color: $green;
    overflow-y: auto;
    margin-top: 20px;
    height: 159px;
    padding: 20px 15px;

    .lookbookSelected {
      div {
        div {
          background-color: $green !important;
        }
        svg {
          fill: $white !important;
        }
        span {
          color: $green !important;
        }
      }
    }
  }
}

.modalFooter {
  //position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px 15px 25px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    width: 100%;
  }
}

.modal {
  //position: fixed;
  //left: 0;
  //top: 0;
  z-index: 7;
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
}

.modalContainer {
  justify-content: center;
}

.lookbookName {
  white-space: nowrap;
}
