@import '~global/styles';

.stickyHeaderWrapper {
    position: fixed;
    top: 62px;
    z-index: 20;
    width: 100%;
    background-color: #fff;
}

.stickyHeader {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    box-shadow: 0 -10px 10px -10px rgba(0, 0, 0, 0.3);
}

.hide {
    opacity: 0;
    pointer-events: none;
}

.show {
    opacity: 1;
    pointer-events: auto;
}
