.tooltipWrapper {
    position: relative;
  
    div.svg {
      display: none !important;
    }
  
    div:not(.svg) {
      display: block;
    }
  
    &:hover {
      div.svg {
        display: flex !important;
        padding-top: 0px !important;
      }
  
      div:not(.svg) {
        display: none;
      }
    }
}
