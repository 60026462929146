.wrapper {
    width: 830px;
    height: 85%;
    z-index: 9999991;
    position: fixed;
    left: calc((100vw - 85px - 830px) / 2 + 85px);
    top: 90px;
    background-color: #ffffff;
    border-radius: 19px;
    box-shadow: 0px 2px 32px 0 rgba(0,0,0,.23);
    box-sizing: border-box;
    padding-bottom: 35px;
    resize: both;
    overflow: hidden;

    .mask {
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        background-color: transparent;
        z-index: 0;
    }
    
    .header {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 20px;
        cursor: move;
        position: relative;
        z-index: 1;

        .iconWrapper {
            width: 24px;
            height: 24px;
            cursor: pointer;
            position: relative;
            z-index: 1;
            top: 4px;
            display: flex;
            justify-content: center;
            align-items: center;

            & > svg {
                cursor: pointer;
            }
        }
    }

    .left {
        width: 30px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        cursor: move;
        z-index: 2;
    }

    .bottom {
        height: 30px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        cursor: move;
        z-index: 2;
    }

    .body {
        width: 100%;
        height: 100%;
        padding-left: 60px;
        padding-right: 60px;
        position: relative;
        z-index: 1;
        overflow: hidden;
    }
}

.vendorWrapper {
    width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 150px;
        max-height: 50px;
    }

    svg {
        max-width: 150px;
        max-height: 50px;
    }
}

.form {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    .count {
        width: 60px;
        height: 60px;
        border-radius: 60px;
        background-color: #01A699;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
    }

    .formContent {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .mainInfo {
            font-size: 20px;
            font-weight: normal;
            color: #666;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 隐藏溢出的文本 */
            text-overflow: ellipsis;
        }

        .dateWrapper {
            font-size: 12px;
            font-weight: normal;
            color: #686868;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .flex1 {
        flex: 1;
    }
}

.table {
    max-height: calc(100% - 80px);
    overflow-y: auto;
    overflow-x: hidden;
}