@import '~global/styles';

@mixin textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.optionItem {
    position: relative;
    z-index: 9999;

    .arrowRight {
        height: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 4px;

        svg {
            width: 12px;
            height: 12px;
            fill: #666;
        }
    }

    .arrowRight.arrowActive {
        svg {
            width: 12px;
            height: 12px;
            fill: $green !important;
        }
    }

    &:hover {
        .optionName:not(.disabled) {
            color: $green;
        }

        .arrowRight:not(.disabled) {
            svg {
                fill: $green;
            }
        }
    }
}

.optionName {
    white-space: nowrap;
    @include textEllipsis();
}

.optionName.active {
    color: $green;
}

// 禁用
.disabled {
    color: $text-light;
    background: none;
    cursor: not-allowed;
    opacity: 0.7;
}

.disabledClick {
    opacity: 1;
    color: unset;
}
