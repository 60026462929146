@import '~global/styles';

.sign-in {
  display: grid;
  grid-template-columns: repeat(2, auto);
  width: 100vw;
  height: 100vh;

  h1 {

  }
  .tips{
    padding-bottom: 80px;
    color:#666666;
  }
  form {
    padding-top: 40px;
  }
  .image {
    background: url('~assets/img/login.png') no-repeat;
    background-size: cover;
  }

  .sign-in-section {
    display: grid;
    justify-items: center;
    align-items: center;
    position: relative;

    .sign-in-form {
      display: grid;
      width: 450px;
    }
  }
}
