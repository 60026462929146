@import '~global/styles';
@import '../../../../components/layout/styles';

.sign-in-inputs {
  display: grid;
  grid-row-gap: 30px;

  .secondary-cta {
    margin-top: 20px;
    @include flex-center-space-between;

    > div {
      padding: 5px 0;
      font-size: 12px;
      color: $text-dark;
    }

    .forgot-password {
      float: right;
      cursor: pointer;
    }
  }
}

.sign-in-button {
  margin-top: 50px;
  float: right;
}
