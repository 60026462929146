@import '~global/styles';

.title {
  color: $text-dark;
  font-weight: normal;

  &.secondary {
    color: $text-light;
  }

  &.xxxl {
    font-size: $font-size-xxxl;
  }

  &.xxl {
    font-size: $font-size-xxl;
  }

  &.xl {
    font-size: $font-size-xl;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}
